<template>
  <div class="">
    <div class="pl-2">
      <table class="table-auto w-full">
        <thead>
          <tr>
            <th class="text-left">
              <div class="flex">
                <div class="text-affypink">PARTNER SITE</div>
                <div>
                  <button @click="sortdown=!sortdown" class="focus:outline-none">
                    <svg v-if="sortdown" class="w-6 h-6 pl-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                    <svg v-else class="w-6 h-6 pl-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                  </button>
                </div>
              </div>
            </th>
            <th class="text-left text-affypink">FULL LINK</th>
            <th class="text-left text-affypink">FROM</th>
            <th class="text-left text-affypink">DESCRIPTION</th>
          </tr>
        </thead>
        <tbody>
              <tr v-for="link in sortedLinks" v-bind:key="link.id">
                <td class="text-affygrey-dark">
                    <button @click="curLink(link)">
                      <div class="flex items-center">
                        <svg class="w-6 h-4 pr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                        <div v-if="link.site_name.length>10" :title="link.site_name">
                          {{ link.site_name|truncate(10) }}
                        </div>
                        <div v-if="link.site_name.length < 11">
                          {{ link.site_name}}
                        </div>
                      </div>
                    </button>
                </td>
                <td>
                  <div v-if="link.owner_link_long!=null" class="flex items-center text-affygrey-dark" >
                    
                    <div v-if="link.owner_link_long.length>40" ><a :href="link.owner_link_long" :title="link.owner_link_long" target=_blank>{{ link.owner_link_long|truncate(40) }}</a></div>
                    <div v-if="link.owner_link_long.length<41" ><a :href="link.owner_link_long" target=_blank>{{ link.owner_link_long|truncate(40) }}</a></div>
                    
                    <div class="pl-1">
                      <a :href="link.owner_link_long" target=_blank>
                        <svg class="w-4 h-4" fill="none" stroke="#807e7e" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                      </a>
                    </div>
                  </div>
                </td>
                <td class="text-affygrey-dark">{{link.linked_from_typedesc}}</td>
                <div v-if="link.owner_link_desc && link.owner_link_desc.length>30" :title="link.owner_link_desc" ><td class="text-affygrey-dark">{{ link.owner_link_desc|truncate(30) }} </td></div>
                <div v-if="link.owner_link_desc && link.owner_link_desc.length<31"><td class="text-affygrey-dark">{{ link.owner_link_desc }}</td></div>
            </tr> 
        </tbody>
      </table> 
    </div>

    
    <div v-if="visible" >

      <!-- Dialog (full screen) -->
      <div class="fixed top-0 left-0 flex justify-end w-full h-full" @click.self="toggleVisible" style="background-color: rgba(0,0,0,.5);" >
        <div class="flex h-full p-2 text-left rounded-bl-lg bg-white shadow-xl md:max-w-xl md:p-3 lg:p-3 md:mx-0">
          <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
            <span @click="toggleVisible" >
              <svg class="w-6 h-6" fill="none" stroke="grey" viewBox="0 0 20 20" role="button" xmlns="http://www.w3.org/2000/svg">
              <title>Close</title>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M6 18L18 6M6 6l12 12"></path></svg>
            </span>
          </div>
          <div>
              <LinkListFullDetails v-bind:link="link" @autoclose="autoCloseAfterDelete"/> 
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>   

import LinkListFullDetails from '@/components/page-parts/links/LinkListFullDetails.vue'
import axios from 'axios';
import { bus } from '../../../main';

export default {
  name: 'linklist',
  components: {
    LinkListFullDetails,
  },

data() {
    return {
      links : [],
      tempLinks: [],
      link: Object,
      visible: "",
      autoclose: "",
      sortdown: true,
      token: localStorage.getItem('user-token') || null,
      searchValue: '',
    }
  },

filters: {
  
  truncate: function (value, size) {

    if (!value) return '';
      value = value.toString();

    if (value.length <= size) {
      return value;
    }
    return value.substr(0, size) + '...';
  }
},

computed: {
    
	sortedLinks() {

    bus.$on('searchChanged', (data) => {
      this.searchValue = data;
     // console.log ("search data:"+this.searchValue)
    })

    let tempLinks = this.links;

//  SORT		
		tempLinks = tempLinks.sort((a,b) => {
			let fa = a.site_name.toLowerCase(), fb = b.site_name.toLowerCase();
			if (fa < fb) {
				return -1
			}
			if (fa > fb) {
				return 1
			}
			return 0
    })

//  SORT REVERSE  . 
    // Show sorted array in descending or ascending order
    if (!this.sortdown) {
      tempLinks.reverse()
    }

//  FILTER on SEARCH TERM
    if (this.searchValue != '' && this.searchValue) {
        tempLinks = tempLinks.filter((item) => {
          return item.site_name
            .toUpperCase()
            .includes(this.searchValue)
        })
    } else {
          tempLinks = this.links;
    }

  return tempLinks
	},
},

beforeCreate: function(){
  if (localStorage.getItem('user-token') === null) {
    window.location.href = '/';
      }
  },

created() {
  this.getLinks();

  // eslint-disable-next-line no-unused-labels
  visible:false;
}, 

methods: {

  toggleVisible() {
      this.visible = !this.visible;
    },

  autoCloseAfterDelete: function(params) {
    this.visible = !params;                                //true passed back to autoclose
    let axiosConfig = {
        headers: {
          'Authorization': 'Token ' + this.token
        }
      };
    axios.get("api/affypartnerlinks/", axiosConfig)   //refresh the data
      .then(res => (this.links = res.data))
      .catch(err => console.log(err));
     // console.log(this.links)         
    },

  getLinks() {
      let axiosConfig = {
        headers: {
          'Authorization': 'Token ' + this.token
        }
      };
      axios.get("api/affypartnerlinks/", axiosConfig)
      .then(res => (this.links = res.data))
      .catch(err => console.log(err));
     // console.log(this.links)
      },

    curLink(link) {
     // When a model is changed, the view will be automatically updated.
      this.link = link;
      this.toggleVisible()
     // console.log("link:"+this.link)
        },
  },
}
</script>