<template>
    <div>
     <ThePageBanner pagetitle="LIST PARTNER LINK LOCATIONS" runfunction="LINKS" />
     <LinkList />
    </div>
                
</template>

<script>
import ThePageBanner from '@/components/template-parts/ThePageBanner.vue'
import LinkList from '@/components/page-parts/links/LinkList.vue'

export default {
  name: 'listlinks',
  components: {
    ThePageBanner,
    LinkList,
  },
  data() {
    return {
      token: localStorage.getItem('user-token') || null,  
    }
  },

  beforeCreate: function(){
    if (this.token === null) {
      window.location.href = '/';
    }
  },
}
</script>