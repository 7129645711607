<template>
  <div class="" >
    <div class="min-h-screen grid grid-cols-2 gap-3 shadow-lg "> 
      <div class="shadow-sm mr-2 min-h-screen"> 
        <div class="flex font-bold items-center text-xl ml-8 pt-6">
          Get to the top faster with Affyboy
          <svg class="w-12 h-12 pt-1" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg> 
        </div>
        <div class="flex">
          <div class="text-xl font-bold ml-8 pt-6 text-gray-300">BETA</div>
          <div class="text-xs pt-8 pl-2 text-gray-300">v 1 . 0 . 24</div>
        </div>
        <div class="p-4 ml-4">
          Manage all your affiliate marketing partner and link details in one place keeping all those pesky details close on hand saving you hours of time <strong>so you can focus on what is important</strong>.
        </div>
        <div class="p-4 ml-4">
          Enjoy your system and tell your friends. <strong>It's free to join.</strong>
        </div>
      </div>
      <!-- only displayed when user NOT logged in -->
      <div v-if="token===null" class="w-full bg-affylogin-light rounded-bl-xl ">  
          <div class="flex ml-6 justify-center pt-2" >
          <div v-if="showlogin">
              <Login @selectedOptions="DisplayScreens"/>
          </div>
          <div v-if="showregister  && !showresetpassword" >
              <Register @selectedOptions="DisplayScreens"/>
          </div>
          <div v-if="showresetpassword" >
              <PasswordReset @selectedOptions="DisplayScreens"/>
          </div>  
        </div>
      </div>
    </div>   
  </div>        
</template>

<script>
import Login from '@/components/template-parts/Login.vue'  
import Register from '@/components/template-parts/Register.vue' 
import PasswordReset from '@/components/template-parts/PasswordReset.vue' 

export default {
  name: 'home',
  components: {
    Login,
    Register,
    PasswordReset,
  },
  data() {
        return {
          showlogin: true,
          showregister: false,
          showresetpassword: false,
          token: localStorage.getItem('user-token') || null, 
        }
  },
  created() {
    
    // showregister=false;
    //  undefined comes in after verifiying email address on sign up
    if (this.token==='undefined' ) {
        this.token=null;
    }
  //  console.log('showresetpassword:'+this.showresetpassword);
  //  console.log('showregister:'+this.showregister);
  //  console.log('showresetpassword:'+this.showresetpassword);
    },

  methods: {

  DisplayScreens(options) {
    this.showresetpassword = options.showresetpassword;
    this.showregister = options.showregister;
    this.showlogin = options.showlogin;
   // console.log('HOME > DisplayScreens>> showresetpassword:'+this.showresetpassword);
   // console.log('HOME >  DisplayScreens>> showregister:'+this.showregister);
   // console.log('HOME >  DisplayScreens>> showlogin:'+this.showlogin);
    },

    ShowRegister: function(params) {
      this.showregister = params;
      this.showresetpassword = false;
   //   console.log('HOME > ShowRegister>> showregister:'+this.showregister);
   //   console.log('HOME >  ShowRegister>> showresetpassword:'+this.showresetpassword);

    },

    ShowPasswordReset: function(params) {
    //  console.log("params:"+params)
    //  console.log("HOME > ShowPasswordReset >> resetpassword:"+this.resetpassword)
      this.showresetpassword = params;
    //  console.log("HOME > ShowPasswordReset >> showresetpassword:"+this.showresetpassword)
      
    },
  },
}
</script>