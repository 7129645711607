<template>
    <div class="">
        <div class="flex mt-5 pb-2 items-center">
            <svg class="w-8 h-8 pr-2" fill="none" stroke="grey" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg> 
            <h2 class="text-lg font-bold pr-2">Are you sure you want to delete this?</h2>
            <button class="focus:outline-none px-3 py-1 bg-red-300 text-xs text-white mr-4  rounded" @click="DeletePartner">YES</button>
            <button class="focus:outline-none px-3 py-1 bg-gray-400 text-xs text-white rounded" @click="closeDelete">oops!</button>
        </div>
        <p class="text-xs text-red-400 font-bold pl-8">All your link locations associated with this partner will also be removed.</p>
    </div>
</template>

<script>    
import axios from 'axios'
//import { TokenService } from '../storage/service'

export default {
    name: 'deletepartner',
    data() {
        return {
            deletevisible: "",
            deletedok: false,
            hidedeletesection: false,
            token: localStorage.getItem('user-token') || null,
        }
    },

    props: { 
        partner: { type: Object },
        },

    created() {
        // eslint-disable-next-line no-unused-labels
        deletevisible: false;
    },

    methods: {

        closeDelete() {
            this.$emit("hidedeletesection", false);
        },

        toggledDleteVisible() {
            this.deletevisible = !this.deletevisible;
        },

        DeletePartner () {
        // deletes the partner and ALL child link records (via DB cascade on django model)
        
        let axiosConfig = {
            headers: {
            'Authorization': 'Token '+ this.token
            }
        }
        
        axios.delete(`api/affypartners/${this.partner.id}/`,axiosConfig)
        .then(function (response) {
         //   console.log(response.data)
        })
        .catch(function (error) {
            console.log(error)
        })
        
        this.$emit("hidedeletesection", false);
        this.$emit("deletedok", true);

        },
    }
}
</script>
