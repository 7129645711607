<template>
  <div class="" >
    <div class="min-h-screen grid grid-cols-2 gap-3 shadow-lg "> 
      <div class="shadow-sm mr-2 min-h-screen"> 
        <div class="flex font-bold items-center text-xl ml-8 pt-6">
          HELP
          <svg class="w-12 h-12 pt-1" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg> 
        </div>
        
      </div>
      
    </div>   
  </div>        
</template>

<script>


export default {
  name: 'home',
  data() {
        return {
          showregister: false,
          token: localStorage.getItem('user-token') || null, 
        }
  },
  created() {
    // eslint-disable-next-line no-unused-labels
    showregister: true;
    },

  methods: {

    ShowRegister: function(params) {
      this.showregister = params;
    },

  },
}
</script>