 <template>
    <div class="">
        <div class="w-full flex justify-between pt-2 pl-2 pr-2 pb-2 bg-affypink-light">
            <div class="pr-5">
                <h2 class="text-xl text-affypink-dark font-bold pt-1">ADD PARTNER AFFILIATE LINK</h2>
            </div>
            <div class="bg-blue-500 rounded flex h-8 items-center">               
                <div class="flex ">
                    <div v-if="savedOK">
                        <div class="text-xs text-affypink py-2 px-2">LINK ADDED</div>
                    </div>
                    
                    <div class="bg-blue-500 px-2 text-white py-2 rounded flex h-8 items-center transition duration-300 ease-in-out hover:bg-blue-600">
                        <button type="button" v-on:click="processlink" class="focus:outline-none">
                            <div class="flex">
                                <div class="pr-1 pt-1 font-bold text-xs">SAVE</div>
                                <div>
                                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"></path></svg>
                                </div>
                            </div>
                        </button>
                     </div>
                </div>
            </div>
        </div>

        <form id="formlink" @submit.prevent="processlink">
            <div id="fulldtls" class="mt-2 p-1 pt-2 m-2 rounded-lg shadow-lg">
                <div v-if="errorsFound" class="pl-3 text-xl text-red-400"> 
                    Please correct the following error(s):
                    <ul class=" text-lg text-red-300">
                        <li v-for="error in errors" :key="error">{{ error }}</li>
                    </ul>
                </div>
                <div class="bg-white p-1 pb-1 mt-1 rounded-lg shadow">
                    <div class="grid grid-cols-2 gap-4 ">
                        <div class="bg-red-200 rounded p-2">
                            
                            <div class="flex">
                                <div class="text-xs">PARTNER SITE NAME</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttPartnerSite">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div v-if="partnerid > 0" class="flex pb-2">
                                <div class="pr-2 text-sm" >
                                    <select id="linktypelist" v-model="partnerid">
                                        <option  value=0>Please select one</option>
                                        <option v-for='partner in partnerlist' :key="partner.id" v-bind:value="partner.id">{{ partner.site_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="partnerid===0" class="flex pb-2">
                                <div class="pr-2 text-sm" >
                                    <select id="linktypelist" v-model="partneritem">
                                        <option  value=0>Please select one</option>
                                        <option v-for='partner in partnerlist' :key="partner.id" v-bind:value="partner.id">{{ partner.site_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="text-xs">FULL LINK</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttFullLink">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2">
                                 <input v-model="owner_link_long" type="url" placeholder="add the full URL link" maxlength="100" size="40">
                               </div>
                            <div class="flex">
                                <div class="text-xs">SHORT LINK</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttShortLink">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2">
                                <input type="url" v-model="owner_link_short" maxlength="27" size="40">
                            </div>
                            
                        </div>
                        <div class="bg-red-200 rounded p-2">
                            <div class="flex">
                                <div class="text-xs">LINK FROM TYPE</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttLinkedFromType">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>         
                            <div class="flex">
                                <div class="pr-2 text-sm" >
                                    <div class="pr-2" v-if="addtype"> 
                                        <input type="text" v-model="linked_from_typedesc" maxlength="12" size="18">
                                    </div>
                                    <div v-if="!addtype">
                                        <select id="linktypelist" v-model="linkitem">
                                            <option disabled value="0">Select or Add</option>
                                            <option v-for='link in links' v-bind:value="link.linked_from_typedesc" :key="link.id" >{{ link.linked_from_typedesc }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex">
                                    <button v-if="!addtype" title="Add" type="button" class="focus:outline-none" @click="addtype=!addtype">
                                        <svg class="w-4 h-4" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>  
                                    <button v-if="addtype" title="Cancel" type="button" class="focus:outline-none" @click="addtype=!addtype">
                                        <svg class="w-4 h-4" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                    </button> 
                                </div>
                            </div>
                            <div class="flex">
                                <div class="text-xs pt-2">LINK DESCRIPTION</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttLinkDescription">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2">
                            <input type="text" v-model="owner_link_desc" maxlength="30" size="40" >
                            </div>

                            <div class="flex">
                                <div class="text-xs">LINK NOTE</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttLinkNote">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2">
                                <input type="text"  v-model="owner_link_note" maxlength="50" size="40">
                            </div>
                            <div class="flex justify-end focus:outline-none">
                                <div class="p-1 rounded ">
                                    <input type="reset" class="bg-affypink text-xs text-white px-2 py-1 rounded transition duration-300 ease-in-out hover:bg-blue-600">
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div> 
            </div>
        </form>
    </div>
</template>

<script>    
import axios from 'axios'
import Vue from 'vue'
import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

export default {
    name: 'addlink',

    props: {
        partnerid: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            partnerIDnum: 0,
            tooltip: "tip1",
            addtype: false,
            savedOK: false,
            token: localStorage.getItem('user-token') || null,
            userid: localStorage.getItem('user-id') || null,
            owner_link_useraffyid: null,
            owner_link_short: null,
            owner_link_long: null,
            owner_link_desc: null,
            owner_link_note: null,
            linked_from_typedesc: null,
            partnerlist: [],
            links: [],
            partneritem: 0,
            errorsFound: false,     // general errors found
            errors: [],
            linkitem: 0,
            ttPartnerSite: 'Partner site this link is connected to.',
            ttFullLink: 'Full URL for where link is located<br>must use http/https',
            ttShortLink: 'Short link to partner site this link is connected to. <br>Must use http/https eg https://getus.at/semrush',
            ttFullLinkSite: 'Partner site this link is connected to.',
            ttLinkedFromType: 'Add your own Link from type eg YouTube',
            ttLinkDescription: 'Optional<br />description about the link.',
            ttLinkNote: 'Optional<br />note about the link.',
        }
    },

    beforeCreate: function(){
    if (localStorage.getItem('user-token') === null) {
      window.location.href = '/';
        }
    },

    created: function(){
    
        this.errorsFound = false;
        this.partneritem = this.partnerid
        this.errors = [];
        this.createPartnerList()       
        this.getLinks()
  //      this.cleanOptionsList
    },

methods: {
        
        createPartnerList: function() {

            // make sure users are logged in
            if (this.token === null) {
                window.location.href = '/';
            }
            let axiosConfig = { headers: { 'Authorization': 'Token ' + this.token  } };
            axios.get('api/affypartnerlist/', axiosConfig)
            .then(resp => {
                this.partnerlist = resp.data;
                if (this.partnerlist.length === 0) {
                    this.errorsFound = true;
                    this.errors.push('There are no current partners - please add one first') 
                }
            })
            .catch(err => console.log("ADDING NEW PARTNER: "+err))
        },

        getLinks() {
            // make sure users are logged in
            if (this.token === null) {
                window.location.href = '/';
            }

            let axiosConfig = { headers: { 'Authorization': 'Token ' + this.token  } };
          
            axios.get("api/linktypes/", axiosConfig)   
            .then(resp => {
                this.links = resp.data;
            })
            .catch(err => console.log("ADDING NEW PARTNER: "+err))
          },

//  VALIDATE THE LINKS DATA BEFORE SAVING
    checkForm() {
        this.errorsFound=false;     // hide error section on page
        this.errors = [];           // reset error message array

        if (this.partnerlist.length === 0) {
            this.errorsFound = true;
            this.errors.push('There are no current partners - please add one first')
            return 
        }

        if (this.partnerid === 0 && (this.partneritem  === null | this.partneritem === 0 )) {
            this.errorsFound = true;
            this.errors.push('Partner Site Name must be selected') 
        }
        
    },
            
        processlink() {

        this.checkForm()
        if (this.errorsFound){
            return
        }

        let axiosConfig = { headers: { 'Authorization': 'Token ' + this.token  } };
        
        var postData = []

        if (this.partnerid > 0) {
            this.partneritem = this.partnerid
        }
        if (this.addtype) {     // uses the text box this.myStr2 = 
            postData = {
            owner_link_useraffyid:this.partneritem,
            owneruserid: this.userid,
            owner_link_short: this.owner_link_short,
            owner_link_long: this.owner_link_long,
            owner_link_desc: this.owner_link_desc,
            owner_link_note: this.owner_link_note,  
            linked_from_typedesc: this.linked_from_typedesc.toUpperCase(),
        }
        } else {            // uses the drop down 
            postData = {
            owner_link_useraffyid: this.partneritem,
            owneruserid: this.userid,
            owner_link_short: this.owner_link_short,
            owner_link_long: this.owner_link_long,
            owner_link_desc: this.owner_link_desc,
            owner_link_note: this.owner_link_note,  
            linked_from_typedesc: this.linkitem
        } 
        }

        axios.post("api/affypartnerlinks/", postData, axiosConfig)
        .then(res => {
         //   console.log(res.data)
            this.savedOK = !this.savedOK;
            this.owner_link_short= '';
            this.owner_link_long= '';
            this.owner_link_desc= '';
            this.owner_link_note= '';
            this.linked_from_typedesc= '';

            this.timer = setTimeout(() => {
                this.savedOK = !this.savedOK;
            }, 2500);


            })
        .catch(err => console.log("ADDING NEW LINK: "+err))

        },
    }
}
</script>

<style>

.tooltip {
  display: block !important;
  z-index: 10000;
  font-size: small;
}

.tooltip .tooltip-inner {
  background:#e75480;
  color: black;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: #e75480;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>