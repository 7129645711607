<template>
    <div>
        <div class="grid grid-cols-3 gap-4 bg-affypink-light pt-2 pl-2 pr-2 pb-2">
            <div class="flex col-span-2 ...">
                <h2 class="text-xl text-affypink-dark font-bold pt-1" >{{ pagetitle }}</h2>   
                <div class="pr-2 pl-12 text-sm pt-2 ">
                    <input  v-if="runfunction === 'PARTNERS'" type="text" width="8" v-model="searchvalue" placeholder="Search Partners" id="search-input">
                    <input  v-if="runfunction === 'LINKS'" type="text" width="8" v-model="searchvalue" placeholder="Search Links" id="search-input">
                    <div v-if="resetSearchVisible" class="p-0">
                        <button @click="clearSearch" class="focus:outline-none" title="RESET">
                            <svg class="w-4 h-4 pt-1" fill="none" stroke="grey" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        </button>
                    </div>
                </div>
                <div class="pt-1">
                    <button @click="runSearch" class="focus:outline-none">
                        <svg class="w-7 h-7" fill="none" stroke="pink" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    </button>
                </div>
            </div>
            <div class="... justify-self-end">
                <!-- PARTNER LINK -->
                <div v-if="runfunction === 'PARTNERS'" > 
                    <div class=" bg-blue-500 rounded flex h-8 items-center">               
                        <router-link to="/add">
                            <div class="flex font-bold text-white px-2 py-1 rounded transition duration-300 ease-in-out hover:bg-blue-600 ">
                                <div class="pr-1 pt-1 text-xs">ADD</div>
                                <div>
                                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> 
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>

                <!-- LINKS LINK -->
                <div v-if="runfunction === 'LINKS'" > 
                    <div class=" bg-blue-500 rounded flex h-8 items-center">               
                        <router-link to="/addlink">
                            <div class="flex font-bold text-white px-2 py-1 rounded transition duration-300 ease-in-out hover:bg-blue-600 ">
                                <div class="pr-1 pt-1 text-xs">ADD</div>
                                <div>
                                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> 
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from '../../main';

export default {
    name: 'pagebanner',
    props: {
      pagetitle: { required:true, type: String, default: "Home" },
      runfunction: { required:true, type: String },          // LINKS .. PARTNERS
    },
    data() {
        return {
          searchvalue: '',
          resetSearchVisible: false,
        }
    },

    methods: {

        clearSearch() {
            this.searchvalue = "";
            this.resetSearchVisible = !this.resetSearchVisible;
            bus.$emit("searchChanged", '');
        },

        runSearch() {
            this.resetSearchVisible = !this.resetSearchVisible;
            bus.$emit("searchChanged", this.searchvalue);
        }
    },
}

</script>