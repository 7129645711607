<template>
    <div class="flex flex-col flex-shrink-0 bg-affyblue h-44 pl-2 pt-5 pr-3 border-b border-blue-600 shadow-xl"> 
        <router-link to="/">
            <div class="flex flex-row">
                <svg class="w-9 h-9" fill="none" stroke="yellow" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg>
                <span class="font-sans text-white pl-2 text-2xl"><strong>affyboy</strong></span>
                <span class="font-sans text-white text-sm pt-2">.com</span>
            </div>
        </router-link>
        <div class="flex flex-row place-self-center pt-2 text-gray-400 text-xs">
            get to the top faster
        </div>
        <div class="flex flex-row place-self-center pt-8 pb-6 text-yellow-300 text-xs">
            YOUR simple affiliate<br>marketing manager
        </div>
    </div>
    
</template>

<script>    

export default {
  name: 'leftmenutop'
}
</script>