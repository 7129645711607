<template>
  <div id="app">        
    <div class="min-h-screen bg-gray-100 min-w-full max-w-full w-full ">
        <div class="flex min-h-screen max-w-full">
            <div class="bg-affyblue"> 
                
                    <TheLeftMenuTop/>
        
                    <TheLeftMenu/>
 
            </div>
            <div class="bg-grey-50 max-w-full w-full"> 
                        <TheBannerTop/>
                <div class="flex flex-row min-h-screen">
                    <div class="bg-affybeige-light w-full">
                        <router-view/>
                    </div>
                    <div v-if="!homepage" class="bg-affybeige shadow-xl">   
                        <TheSideBarAds />
                    </div> 
                </div>
            </div>
        </div>
    </div>
        
    <div class="p-3 ">
      <div class="flex">
        <div  class="pr-2 text-sm">
          We value your <strong><a href='https://form.jotform.com/203011752076445'>feedback</a></strong>
        </div>
        <div class="px-2 text-sm">
          |
        </div>
        <div class="text-xs pl-2 py-1 items-center">
            <div>
              <router-link to="/">
                <div class="font-bold text-opacity-75 text-black hover:text-blue-400 focus:text-blue-800">
                  Login
                </div>
              </router-link>
            </div>
        </div>
        <div class="text-xs pl-2 py-1 items-center">
            <div>
              <router-link to="/privacy">
                <div class=" text-opacity-75 text-black hover:text-blue-400 focus:text-blue-800">
                  Privacy
                </div>
              </router-link>
            </div>
        </div>
        <div class="text-xs pl-2 py-1 items-center">
            <div>
              <router-link to="/security">
                <div class=" text-opacity-75 text-black hover:text-blue-400 focus:text-blue-800">
                  Security
                </div>
              </router-link>
            </div>
        </div>
        <div class="text-xs pl-2 py-1 items-center">
            <div>
              <router-link to="/releasenotes">
                <div class=" text-opacity-75 text-black hover:text-blue-400 focus:text-blue-800">
                  Release Notes
                </div>
              </router-link>
            </div>
        </div>
        <div class="text-xs pl-2 py-1 items-center">
            <div>
                <div class="pl-5 text-opacity-75 text-black focus:text-blue-800">
                  &copy; COPYRIGHT - ALL RIGHTS RESERVED
                </div>
            </div>
        </div>
      </div>
    </div>

    

  </div>
</template>

<script>    
import TheLeftMenuTop from '@/components/template-parts/TheLeftMenuTop.vue' 
import TheLeftMenu from '@/components/template-parts/TheLeftMenu.vue' 
import TheBannerTop from '@/components/template-parts/TheBannerTop.vue'   
import TheSideBarAds from '@/components/template-parts/TheSideBarAds.vue'
 

export default {
  name: 'App',

  data() {
    return {
      homepage: true,
      token: localStorage.getItem('user-token') || null,
    }
  },

  components: {
    TheLeftMenuTop,
    TheLeftMenu,
    TheBannerTop,
    TheSideBarAds
  },

  created() {
    if (this.token !== null) {
      this.homepage = false;
     // console.log("token:"+this.token);
    }
  },

  methods: {

    ishome() {
   //   console.log("token:"+this.token);
      this.homepage = !this.homepage
    }
  },
}
</script>

<style src="./assets/tailwind.css">