<template>
  <div class="" >
    <div class="min-h-screen shadow-lg "> 
      <div class="shadow-sm mr-2 min-h-screen"> 
        <div class="flex font-bold items-center text-xl ml-8 pt-6">
          Get to the top faster with Affyboy
          <svg class="w-12 h-12 pt-1" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg> 
        </div>
        <div class="p-4 ml-4 font-bold">
          SECURITY STATEMENT
        </div>
        <div class="p-14">
            <div class="pt-4">
                The security of your data is a top priority. Affyboy's solution is designed to protect your data, and our hosting center is among the most advanced in the world:

<div class="pt-2">- 2048-bit Secure Socket Layer (SSL) technology, using both server authentication and data encryption</div>
<div class="pt-2">- User authentication with unique usernames and passwords that are encrypted during transmission</div>
<div class="pt-2">- User and session information are kept using advanced security methods based on dynamic data and encoded session ID’s</div>
<div class="pt-2"><div class="pt-2">- Databases are encrypted</div>
<div class="pt-2">- Data is stored on world-class redundant storage SAN disks, with backups every 15 minutes.</div>
<div class="pt-2">- Our SSAE16 audited hosting center has redundant electrical generators, video surveillance, badge-only access and state-of-the-art water and fire detection systems</div>
            </div>

</div>

        </div>
      </div>
    </div>   
  </div>        
</template>

<script>


export default {
  name: 'security',
  
  data() {
        return {
           
        }
  },
  

  methods: {

  

  },
}
</script>