<template>
    <div>
      <ThePageBanner pagetitle="LIST PARTNERS" runfunction="PARTNERS" />
     <PartnerList/>
    </div>
                
</template>

<script>
import ThePageBanner from '@/components/template-parts/ThePageBanner.vue'  
import PartnerList from '@/components/page-parts/partner/PartnerList.vue'

export default {
  name: 'list',
  components: {
    ThePageBanner,
    PartnerList

  },
  data() {
    return {
      token: localStorage.getItem('user-token') || null,  
    }
  },

  beforeCreate: function(){
    if (this.token === null) {
      window.location.href = '/';
    }
  },
}
</script>