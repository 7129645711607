<template>
  <div class="" >
    <div class="min-h-screen grid grid-cols-2 gap-3 shadow-lg "> 
      <div class="shadow-sm mr-2 min-h-screen"> 
        <div class="flex font-bold items-center text-xl ml-8 pt-6">
          <h1>PASSWORD RESET</h1>
          <svg class="w-12 h-12 pt-1" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg> 
        </div>
        <div>    
          <h3 class="text-gray-600 pl-12 pr-12 pt-2">Add your new password below.</h3>
        </div>
        <div class="mt-6 w-56 justify-items-center pl-12 px-5 py-4">
          <form @submit.prevent="ResetPasswordValues" class="flex flex-col" method="POST" action="#">
            <div class="mb-2 pt-2 rounded bg-gray-200">
                <label class="block text-gray-700 text-xs font-bold mb-1 ml-3">New Password</label>
                <input type="password" v-model="password1" class="bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 transition duration-500 px-3 ">
                <label class="pt-6 lock text-gray-700 text-xs font-bold mb-1 ml-3 " for="email">Re-enter Password</label>
                <input type="password" v-model="password2" class="bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 transition duration-500 px-3 ">
            </div>
            <button type="button" @click="ResetPasswordValues">
              <div class="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200" type="submit">
                Reset Now
              </div>
            </button>
          </form>
        </div>
       
        <div class="pl-12 justify-items-center pt-2 text-l text-red-300" v-if="usermessage">  <!-- message -->
          {{displaymessage}}
        </div>

        <div class="place-items-end flex px-5 py-4">
          <button type="button" @click="Cancel" class="focus:outline-none">
            <div class="bg-red-400 hover:bg-purple-700 text-white font-bold my-4 px-3 rounded shadow-lg hover:shadow-xl transition duration-200">Close</div>
          </button> 
        </div>
      </div>
      
    </div>   
  </div>        
</template>

<script>  
import axios from 'axios'

export default {
  name: 'passwordresetvalues',
  data() {
        return {
          displaymessage: '',
          password1: '',
          password2: '',
          usermessage: false,
          uid: this.$route.params.uid,
          emailtoken: this.$route.params.token,
          token: localStorage.getItem('user-token') || null,
        }
  },
 
  methods: {

    Cancel() {
    //  this.$emit("showresetpassword", false);  //show login instead
      console.log('cancelled');
      this.$router.push('/')
    },

    async ResetPasswordValues() {
      console.log("token:"+this.emailtoken);
      console.log("password1:"+this.password1);
      console.log("password2:"+this.password2);
      console.log("URL:"+'rest-auth/password/reset/confirm/'+this.uid+'/'+this.emailtoken+'/')
      console.log("uid:"+this.uid);
      const resp = await axios.post('rest-auth/password/reset/confirm/'+this.uid+'/'+this.emailtoken+'/', {
          new_password1: this.password1,
          new_password2: this.password2,
          uid: this.uid,
          token: this.emailtoken,
      })
      
      .then(resp => {
      this.displaymessage = 'Password reset DONE - please login with your new details';
      this.$router.push('/');   //push to home page so they can login
      })
      .catch(err => {
        console.log("email Error:"+err);
        this.displaymessage = 'Password reset error please try again.';
      })
      this.usermessage = true;
    },

  }
}
</script>