<template>
    <div class="min-h-screen">
       <AddLinkDtls v-bind:partnerid="partnerid"/>
    </div>
                
</template>

<script>
import AddLinkDtls from '@/components/page-parts/links/AddLinkDtls.vue'

export default {
  name: 'addlink',
  components: {
    AddLinkDtls,
  },
  data() {
    return {
      token: localStorage.getItem('user-token') || null,  
      partnerid: 0,
    }
  },

  mounted(){
     this.partnerid=this.$route.params.partnerid;   // pick up partner ID if one sent through from list of partners screen
    },

  beforeCreate: function(){
    if (localStorage.getItem('user-token') === null) {
      window.location.href = '/';
        }
    },

}
</script>