import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import ListPartners from './views/ListPartners.vue'
import AddPartner from './views/AddPartner.vue'
import ListLinks from './views/ListLinks.vue'
import AddLink from './views/AddLink.vue'
import Help from './views/Help.vue'
import Login from './components/template-parts/Login.vue'
import PasswordReset from './components/template-parts/PasswordReset.vue'
import PasswordChange from './views/PasswordChange.vue'
import Privacy from './views/subpages/Privacy.vue'
import Security from './views/subpages/Security.vue'
import ReleaseNotes from './views/subpages/ReleaseNotes.vue'

Vue.use(VueRouter)

  const routes = [
    {
      path: '/',
      name: 'home',
      component:Home
    },
    {
      path: '/login',
      name: 'login',
      component:Login
    },
    {
      path: '/password-reset',
      component: PasswordReset,
    },
    {
      path: '/passwordchange/:uid/:token',
      component: PasswordChange,
    },
    {
      path: '/list',
      name:'list',
      component:ListPartners
    },
    {
      path: '/add',
      name:'add',
      component:AddPartner
    },
    {
      path: '/links',
      name:'links',
      component:ListLinks
    },
    {
      path: '/addlink',
      name:'addlinks',
      component:AddLink
    },
    {
      path: '/addlink/:partnerid',
      name:'addlinkwithid',
      component:AddLink
    },
    {
      path: '/help',
      name:'help',
      component:Help
    },
    {
      path: '/privacy',
      component: Privacy,
    },
    {
      path: '/releasenotes',
      component: ReleaseNotes,
    },
    {
      path: '/security',
      component: Security,
    },
  ]


const router = new VueRouter({
  routes
})

export default router
