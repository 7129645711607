<template>
    <div class="">  
        <div class="flex w-full justify-end pt-2 pr-4">
            <div class="flex content-right">
                <div v-if="!showeditpartner" class="mr-2 bg-blue-500 px-2 text-white py-2 rounded flex h-8 items-center transition duration-300 ease-in-out hover:bg-blue-600">
                    <button type="button" v-on:click="deletevisible=!deletevisible, hideEdit=!hideEdit" class="focus:outline-none">
                        <div class="flex">
                            <div class="pr-1 pt-1 font-bold text-xs">DELETE</div>
                            <div>
                                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg> 
                         </div>
                        </div>
                    </button>
                </div>
                <div v-if="!showeditpartner && !hideEdit" class=" bg-blue-500 px-2 text-white py-2 rounded flex h-8 items-center transition duration-300 ease-in-out hover:bg-blue-600">
                    <button type="button" v-on:click="editPartner" class="focus:outline-none">
                        <div class="flex">
                            <div class="pr-1 pt-1 font-bold text-xs">EDIT</div>
                            <div>
                                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
                          </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>

        <!-- DELETE PARTNER  -->
        <div v-if="deletevisible" >
            <DeletePartner v-bind:partner="partner" @hidedeletesection="hideDeleteValue" @deletedok="deleteOK"/>
        </div>

        <!-- DELETE Ok Message -->
        <div v-if="deleteok" >
            <DeleteOk/>
        </div>

         <!-- EDIT PARTNER -->
        <div v-if="showeditpartner" >       <!-- show edit partner -->
            <EditPartner v-bind:partner="partner" />
        </div>

        <!-- SAVED Ok Message -->
        <div v-if="savedok" >
            <SavedOk/>
        </div>

        <div v-if="!showeditpartner">   <!-- show edit partner screen -->
            <div class="relative p-2 bg-white w-full  m-auto flex-col flex">
                <div class="bg-white p-2 pb-2 mt-3 rounded-lg shadow">
                    <div class="grid grid-cols-2 gap-3 ">
                        <div class="bg-red-200 rounded p-2">
                            <div class="text-xs font-bold text-affypink-dark">
                                PARTNER NAME
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ partner.site_name }} 
                            </div>
                            <div class="text-xs font-bold text-affypink-dark">
                                NETWORK
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ formatField(partner.site_network_name) }} 
                            </div>
                            <div class="text-xs font-bold text-affypink-dark">
                                REGISTERED EMAIL
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ formatField(partner.registered_email) }} 
                            </div>
                            <div class="flex text-xs font-bold text-affypink-dark">
                                MAIN SITE URL
                                <a :href="partner.mainsite_url" target=_blank class="pl-2">
                                        <svg class="w-4 h-4" fill="none" stroke="#807e7e" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                                </a> 
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ formatField(partner.mainsite_url) }}  
                            </div>
                            <div class="text-xs font-bold text-affypink-dark">
                                CATEGORY
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ partner.user_category_desc }}
                            </div>
                            <div class="text-xs font-bold text-affypink-dark">
                                STATUS
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ partner.user_status_desc }}
                            </div>
                            <div class="text-xs mt-4 text-affypink-dark font-bold">DESCRIPTION</div>
                            <div class="w-full bg-pink-50 rounded p-2 mt-1 text-xs text-gray-500">
                                {{ formatField(partner.site_description) }}
                            </div> 
                            <div class="text-xs mt-4 text-affypink-dark font-bold">NOTES</div>
                            <div class="w-full bg-pink-50 rounded p-2 mt-1 text-xs text-gray-500">
                                {{ formatField(partner.site_notes) }}
                            </div> 
                        </div>
                        <div class="bg-red-200 rounded p-2">
                            <div class="text-xs font-bold text-affypink-dark">
                                NUM LINKS
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ partner.num_affy_links }}
                            </div>
                            <div class="text-xs font-bold text-affypink-dark">
                                AFFILIATE A/C CODE
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ formatField(partner.affiliate_account_code) }}
                            </div>
                            <div class="text-xs font-bold text-affypink-dark">
                                AFFILIATE CONTACT NAME
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ formatField(partner.affiliate_manager_contact) }}
                            </div>
                            <div class="text-xs font-bold text-affypink-dark">
                                AFFILIATE CONTACT EMAIL
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ formatField(partner.affiliate_contact_email) }}
                            </div>
                            <div class="flex text-xs font-bold text-affypink-dark">
                                TRACKING URL
                                <a :href="partner.tracking_url" target=_blank class="pl-2">
                                        <svg class="w-4 h-4" fill="none" stroke="#807e7e" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                                </a>
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ formatField(partner.tracking_url) }}
                            </div>
                            <div class="flex text-xs font-bold text-affypink-dark">
                                SHORT TRACKING URL
                                <a :href="partner.getusat_tracking_short_url" target=_blank class="pl-2">
                                        <svg class="w-4 h-4" fill="none" stroke="#807e7e" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                                </a> 
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ formatField(partner.getusat_tracking_short_url) }}
                            </div>
                            <div class="flex">
                                <div class="flex text-xs font-bold text-affypink-dark">
                                    PARTNER ADMIN LOGIN
                                    <a :href="partner.affiliate_login_url" target=_blank class="pl-2">
                                        <svg class="w-4 h-4" fill="none" stroke="#807e7e" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                                    </a> 
                                </div>
                                
                            </div>
                            <div class="text-sm pb-2 text-white break-words">
                                {{ formatField(partner.affiliate_login_url) }}
                            </div>
                            <div class="text-xs font-bold text-affypink-dark">
                                LOGINS
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ partner.user_logins_desc }} 
                            </div>
                            <div class="text-xs font-bold text-affypink-dark">
                                PAYOUT METHOD
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ formatField(partner.payout_method) }} 
                            </div>
                            <div class="text-xs font-bold text-affypink-dark">
                                PAYOUT RATE AMT
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ partner.payout_rate_amount }}
                            </div>
                            <div class="text-xs font-bold text-affypink-dark">
                                COOKIE LIFE (days)
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ partner.cookie_life_days }}
                            </div>
                            <div class="text-xs font-bold text-affypink-dark">
                                PAYOUT RATE %
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ partner.payout_rate_percent }}
                            </div>
                        </div>
                    </div>
                    <div class="text-xs text-center pt-2 text-gray-400">
                        Date Started {{ partner.date_stated }} | Last Edited {{ partner.last_edited }} | Date Closed {{ partner.date_ended }} || Active {{ partner.active }}
                    </div>  
                    
                </div>  
            </div>
        </div>  <!-- end of display informatio for parner -->
    </div>
</template>

<script>    
import EditPartner from '@/components/page-parts/partner/EditPartner.vue'
import DeletePartner from '@/components/page-parts/partner/DeletePartner.vue'
import DeleteOk from '@/components/page-parts/partner/DeleteOk.vue'
import SavedOk from '@/components/page-parts/partner/SavedOk.vue'

export default {
  name: 'partnerfull',
  components: {
  EditPartner,
  DeletePartner,
  DeleteOk,
  SavedOk,
  },

data() {
    return { 
        visible: false,
        hideEdit: false,
        hidevisible: false,
        hidedeletesection: false,
        deletevisible: false,  
        deleteok: false, 
        showeditpartner: false,   
        savedok: false, 
    }
},

props: {
    partner: {},
    },

created() {
    // eslint-disable-next-line no-unused-labels
    deleteok: false;
    // eslint-disable-next-line no-unused-labels
    showeditpartner: false;
    },

methods: {

    formatField (fld) {
        if (fld === '' | fld === null) {
            return "NONE"
        } else return fld
    },

    toggleVisible() {
      this.visible = !this.visible;
    },

    hideDeleteValue: function(params) {
      this.deletevisible = params;
      this.hideEdit = params;
      
    },

    editPartner() {
        this.showeditpartner = true;
    },

    deleteOK: function(params) {
      this.deleteok = params;
      if( this.deleteok )
        {
        this.deletevisible=false;
        this.timer = setTimeout(() => { 
            this.deleteok = false;          // hide after 2 seconds
            this.$emit("autoclose", true);
            }, 2000);
        }
    },
  }
}
</script>