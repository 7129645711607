<template>
    <div>
    
        <div class="min-h-screen bg-affybeige min-w-max pr-2 pl-2 shadow-xl">
            <div class="flex pb-2 pt-2">
                <a href="https://www.cloudways.com/en/?id=426795" target="_top">
                    <img class="border border-black" :src="require(`@/assets/images/cloudways-hosting.jpg`)" alt="Load WordPress Sites in as fast as 37ms!" title="Load WordPress Sites in as fast as 37ms!" width="160" height="361"/>
                </a> 
            </div>
            <div class="flex pt-2">
                <a href="https://www.cloudways.com/en/?id=426795" target="_top">
                    <img class="border border-black" :src="require(`@/assets/images/cloudways-hosting2.jpg`)" alt="Load WordPress Sites in as fast as 37ms!" title="Load WordPress Sites in as fast as 37ms!" width="160" height="160"/>
                </a> 
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ads',
}
</script>

