<template>
  <div  v-if="token==null" class="w-full p-2 pt-4 rounded-xl shadow-xl bg-affylogin-light min-h-screen">      
    <div class=" p-8 pb-3 bg-white w-full rounded-md shadow-sm focus-within:border-blue-500">
      <div class=" mx-auto">        
        <h1 class="text-2xl font-bold text-affypink text-center">Get Started</h1>
      </div>
      <div class="bg-white mx-auto pl-12 pr-12 pt-4 pb-4  my-6 rounded-lg shadow-2xl">
        <div>    
          <h3 class="font-bold text-xl pl-12 pr-12">Welcome to Affyboy</h3>
          <p class="text-gray-600 pl-12 pr-12 pt-1">Sign in to your account.</p>
        </div>
        <div class="mt-6">
          <form @submit.prevent="login" class="flex flex-col" method="POST" action="#">
            <div class="mb-2 pt-2 rounded bg-gray-200">
                  <label class="block text-gray-700 text-xs font-bold mb-1 ml-3" for="email">Email</label>
                  <input type="email" v-model="username" class="bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 transition duration-500 px-3 pb-1">
            </div>
            <div class="mb-4 pt-3 rounded bg-gray-200">
                <label class="block text-gray-700 text-xs font-bold mb-1 ml-3" for="password">Password</label>
                <input type="password" v-model="password" class="bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 transition duration-500 px-3 pb-1">
            </div>
            <div class="flex justify-end">
                <button type="button" @click="ResetPW" class="focus:outline-none">
                  <div class="text-sm text-purple-600 hover:text-purple-700 hover:underline mb-6">Forgot your password?</div>
                </button> 
            </div>
            <button :disabled='isDisabled' class="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 rounded shadow-lg hover:shadow-xl transition duration-200" type="submit">
              Sign In
            </button>
          </form>
          <div  v-if="loginerror" class="pt-2 text-l text-red-300" >  <!-- error message -->
              <span v-html="errormessage"></span>
          </div>
          <div class="flex justify-end">
            <button type="button" @click="Register" class="focus:outline-none">
              <div class="text-blue-500 pt-2 font-bold hover:underline">Don't have an account? Sign Up</div>
            </button> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>    
import axios from 'axios'

export default {
  name: 'login',
  data() {
        return {
          username: '',
          password: '',
          loginerror: false,
          resetpassword: false,
          errormessage: '',
          token: localStorage.getItem('user-token') || null,
          email: localStorage.getItem('user-email') || null,
          userid: localStorage.getItem('user-id') || null,
        }
  },

  created() {

    //  undefined comes in after verifiying email address on sign up
      if (this.token==='undefined' ) {
          this.token=null;
      }
    },
  
  computed: {
    isDisabled: function() {
      if (this.username==='' || this.password==='') {
        return true;
        } else {
          return false;
        }
    }
  },
  
  methods: {

    ResetPW() {
     this.$emit('selectedOptions', {
        showresetpassword: true,
        showregister: false,
        showlogin: false,
        showresetpasswordvalues: false,
      });
    },

    Register() {

      this.$emit('selectedOptions', {
        showresetpassword: false,
        showregister: true,
        showlogin: false,
        showresetpasswordvalues: false,
      });
    },

    /* eslint-disable */
    login() {
      this.loginerror = false;
      this.errormessage = '';
      axios.post('api/login/', {
    //  axios.post('dj-rest-auth/login/', {
          username: this.username,
          email: this.username,
          password: this.password,
      })
      .then(resp => {
        this.token = resp.data.key;
        console.log("LOGGED IN:"+ this.token)
        localStorage.setItem('user-token', this.token)
        localStorage.setItem('user-email', this.username)
        // we now need to pick up the user ID and store it
        let axiosConfig = {
        headers: {
          'Authorization': 'Token ' + this.token
         }
        };
        axios.get('api/users/', axiosConfig)
        .then(resp => {
          localStorage.setItem('user-id', resp.data[0].id)
          this.userid=resp.data[0].id           // only 1 record returned from API thats the one for logged in user
          window.location.href = '/'      //refresh page so new settings are displayed
        })
        .catch((error) => {
            console.log("errcode user get:"+error)
        })
      })
      .catch((error) => {
          localStorage.removeItem('user-token')
          this.loginerror=!this.loginerror
          this.errormessage="<strong>LOGIN FAILED!</strong><br />Please check your email<br />and password and try again.<br />*** If you have just signed up<br />please verify your email address.***"
          console.log("errcode"+error)
      })
    },
    /* eslint-enable */

  
 // if (error.response.status === 401) 
    
   //   .catch( 
    //    localStorage.removeItem('user-token')
  //      loginerror=!loginerror
  //      errormessage="Sorry Login failed - Please try again"
   //   )
    
  }
}
</script>