<template>
    <div>
       <AddPartnerDtls/>
    </div>
                
</template>

<script>
import AddPartnerDtls from '@/components/page-parts/partner/AddPartnerDtls.vue'

export default {
  name: 'add',
  components: {
    AddPartnerDtls,
  },

  data() {
    return {
      token: localStorage.getItem('user-token') || null,  
    }
  },

  beforeCreate: function(){
    if (this.token === null) {
      window.location.href = '/';
    }
  },
}
</script>