<template>
  <div  v-if="token==null || registrationsuccess" class="w-full p-2 pt-4 rounded-xl shadow-xl bg-affylogin-light min-h-screen">      
    <div class=" p-8 pb-3 bg-white w-full rounded-md shadow-sm focus-within:border-blue-500">
      <div class=" mx-auto">        
        <h1 class="text-2xl font-bold text-affypink text-center">Get Started</h1>
      </div>
      <div class="bg-white mx-auto pl-12 pr-12 pt-4 pb-4  my-6 rounded-lg shadow-2xl">
        <div>    
          <h3 class="font-bold text-xl pl-12 pr-12">Welcome to Affyboy</h3>
          <p v-if="!registrationsuccess" class="text-gray-600 pl-12 pr-12 pt-1">SIGN UP for free.</p>
        </div>
        <div class="mt-6">
          <form v-if="!registrationsuccess" @submit.prevent="Register" class="flex flex-col" method="POST" action="#">
            <div class="mb-2 pt-2 rounded bg-gray-200">
                <label class="block text-gray-700 text-xs font-bold mb-1 ml-3" for="email">Email</label>
                <input type="email" v-model="username" class="bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 transition duration-500 px-3 pb-1">
            </div>
            <div class="mb-4 pt-3 rounded bg-gray-200">
                <label class="block text-gray-700 text-xs font-bold mb-1 ml-3" for="password">Password</label>
                <input type="password" v-model="password" class="bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 transition duration-500 px-3 pb-1">
            </div>
            <div class="flex justify-end">
                <button type="button" @click="ResetPW" class="focus:outline-none">
                  <div class="text-sm text-purple-600 hover:text-purple-700 hover:underline mb-6">Forgot your password?</div>
                </button> 
            </div>
            <button :disabled='isDisabled' class="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 rounded shadow-lg hover:shadow-xl transition duration-200" type="submit">
                Sign Up ~ It's Free
            </button>
          </form>
          <div class="pt-2 text-xl text-red-300" v-if="registrationerror">  <!-- error message -->
              <span v-html="errormessage"></span>
          </div>
          <div class="flex pt-2" v-if="registrationsuccess">  <!-- error message -->
            <h1>Account Created! Yahoo! Please
              <button class="pl-2 font-extrabold" type="button" @click="LoginAfterReg">Login</button>
            </h1>
          </div>
          <div v-if="!registrationsuccess" class="flex justify-end">
            <button  type="button" @click="Login" class="focus:outline-none">
              <div class="text-blue-500 pt-2 font-bold hover:underline">Already have an account? Sign In</div>
            </button> 
          </div>
        </div>
      </div>
    </div>
    <div class="pt-3 text-center" v-if="registrationsuccess">
      <div class="pt-2" v-html="message1"></div>
      <div class="pt-2 text-white text-xl" v-html="message2"></div>
      <div class="pt-2" v-html="message3"></div>
    </div>
  </div>
</template>

<script>    
import axios from 'axios'

export default {
  name: 'register',
  data() {
        return {
          username: '',
          password: '',
          registrationerror: false,
          resetpassword: false,
          errormessage: '',
          registrationsuccess: false,
          message1: '<strong>You will need to confirm your email</strong><br />before being able to login.',
          message2: '<strong>Please CONFIRM your email now.</strong>',
          message3: 'If the confirmation email is in SPAM<br /><strong>please WHITELIST</strong> our email address.',
          token: localStorage.getItem('user-token') || null,
        }
  },

  computed: {
    isDisabled: function() {
      if (this.username==='' || this.password==='' || this.registrationsuccess) {
        return true;
        } else {
          return false;
        }
    }
  },
 
  methods: {

    Login() {

      this.$emit('selectedOptions', {
        showresetpassword: false,
        showregister: false,
        showlogin: true,
        showresetpasswordvalues: false,
      });
    },

    ResetPW() {

    this.$emit('selectedOptions', {
        showresetpassword: true,
        showregister: false,
        showlogin: false,
        showresetpasswordvalues: false,
      });
    },

    LoginAfterReg() {
     // this.$emit("login", true);  //show login 
      this.loginerror = false;
      this.errormessage = '';
      let axiosConfig = {
        headers: {
          'Authorization': 'Token ' + this.token
        }
      };
    //  axios.post('dj-rest-auth/logout/', axiosConfig)
      axios.post('api/logout/', axiosConfig)
      .then(resp => {
      localStorage.removeItem('user-token')
      localStorage.removeItem('user-email')
      localStorage.removeItem('user-id')
      this.token = null;
      this.userid = null;
      this.email = null;
      window.location.href = '/'   //refresh so login screen show again
      })
      .catch((error) => {
          console.log("errcode:"+error.response.status)
      })
    },

    /* eslint-disable */
    Register() {
      this.loginerror = false;
      this.registrationsuccess = false;
      this.registrationerror = false;
      this.errormessage = '';

      //console.log("username:"+this.username);
      //console.log("email:"+this.username);
      //console.log("password1:"+this.password);
      //console.log("password2:"+this.password);
     // console.log("registed - st1");
    // axios.post('dj-rest-auth/registration/', {
      axios.post('api/registration/', {
          username: this.username,
          email: this.username,
          password1: this.password,
          password2: this.password,
      })
      .then(resp => {
      this.token = resp.data.key;
      localStorage.setItem('user-token', resp.data.key)
      localStorage.setItem('user-email', this.username)
      this.registrationsuccess = true;
      this.registrationerror = false;
      this.errormessage = '';
     // console.log("registed - st2");
    
      })
      .catch(err => {
        //console.log("API Error:"+err);
        this.registrationerror = true;
        this.errormessage = 'Registration Error please try again.<br />Are you sure you are not already registered?';
      })
    },
    /* eslint-enable */
  }
}
</script>