<template>
    <div class="">
        
        <!-- save button for Edit -->
       
        <div class="flex justify-end">
            <div v-if="saveOK">
                <div class="bg-blue-500 rounded-tl-lg rounded-bl-lg text-xs text-affypink py-2 px-2">PARTNER UPDATED</div>
            </div>
            <div v-if="!showeditpartner" class="bg-blue-500 px-2 text-white py-2 rounded flex h-8 items-center transition duration-300 ease-in-out hover:bg-blue-600">
                <button type="button" v-on:click="savePartner" class="focus:outline-none">
                    <div class="flex">
                        <div class="pr-1 pt-1 font-bold text-xs">SAVE</div>
                        <div>
                            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    
        <form id="formpartner" @submit.prevent="savePartner"> 
            <div class="relative p-2 bg-white w-full  m-auto flex-col flex">
                <div v-if="errorsFound" class="pl-3 text-xl text-red-400"> 
                    Please correct the following error(s):
                    <ul class=" text-lg text-red-300">
                        <li v-for="error in errors" :key="error">{{ error }}</li>
                    </ul>
                </div>
                <div class="bg-white p-2 pb-2 mt-3 rounded-lg shadow">
                    <div class="grid grid-cols-2 gap-4 ">
                        <div class="bg-red-200 rounded p-2">
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">PARTNER NAME</div>                             
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttPartnerName">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="flex text-sm pb-2">
                                <div class="pr-2 text-gray-600">
                                    <input v-model="partner.site_name" type="text" placeholder="add your name for the partner site" maxlength="40" size="30">
                                </div>
                                <svg class="w-2 h-2" fill="none" stroke="red" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path></svg>
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">NETWORK NAME</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttNetworkName">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                                <input type="text" v-model="partner.site_network_name" maxlength="40" size="30">
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">REGISTERED EMAIL</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttRegisteredEmail">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                                <input type="email" v-model="partner.registered_email" :id="partner.registered_email" i maxlength="75" size="30">
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">MAIN SITE URL</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttMainSiteURL">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                                <input type="text" v-model="partner.mainsite_url" maxlength="100" size="30">
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">CATEGORY</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttCategory">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="pr-2 text-sm text-gray-600" >
                                    <div class="pr-2" v-if="addCat"> 
                                        <input type="text" v-model="catItem" maxlength="12" size="18">
                                    </div>
                                    <select v-if="!addCat"  v-model="partner.user_category" required>
                                        <option disabled value=0>Select or Add</option>
                                        <option v-for="category in categories" :value=category.id :key="category.id">{{category.user_category}}</option>
                                    </select>
                                </div>
                                    <button v-if="!addCat" title="Add" type="button" class="focus:outline-none" @click="addCat=!addCat">
                                        <svg class="w-4 h-4" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button> 
                                    <button v-if="addCat" title="Cancel" type="button" class="focus:outline-none" @click="addCat=!addCat">
                                        <svg class="w-4 h-4" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                    </button> 
                            </div>
                            <div class="flex">
                                <div class="pr-2 pt-2 text-xs font-bold text-affypink-dark">STATUS</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttStatus">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="pr-2 text-sm pb-2 text-gray-600">
                                    <select v-model="partner.affy_status">
                                        <option disabled value=0>Please select one</option>
                                        <option v-for="statusOption in statusOptions" :value=statusOption.id :key="statusOption.id">{{statusOption.status_desc}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">PARTNER SITE DESRIPTION</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttPartnerSiteDescription">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                                <textarea v-model="partner.site_description" rows="4" cols="28" maxlength="200">
                                </textarea>
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">PARTNER SITE NOTES</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttPartnerSiteNotes">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                                <textarea v-model="partner.site_notes" rows="4" cols="28" maxlength="200">
                                </textarea>
                            </div>
                        </div>
                        <div class="bg-red-200 rounded p-2">
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">AFFILIATE A/C CODE</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttAffiiliateACcode">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                                <input type="text" v-model="partner.affiliate_account_code" maxlength="40" size="30" >
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">AFFILIATE CONTACT NAME</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttAffiliateContactName">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                            <input type="text" v-model="partner.affiliate_manager_contact" maxlength="30" size="30" >
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">AFFILIATE CONTACT EMAIL</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttAffiliateContactEmail">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                            <input type="email" v-model="partner.affiliate_contact_email" maxlength="75" size="30" >
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">TRACKING URL</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttTrackingURL">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                                <input type="url" v-model="partner.tracking_url" maxlength="100" size="30">
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">TRACKING SHORT URL</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttTrackingShortURL">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                                <input type="url" v-model="partner.getusat_tracking_short_url" maxlength="40" size="30">
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">PARTNER ADMIN LOGIN </div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttPartnerAdminLogin">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                                <input type="text" v-model="partner.affiliate_login_url" maxlength="100" size="30">
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">LOGINS</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttLogins">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="text-sm pr-2">
                                    <div class="pr-2" v-if="addLogin"> 
                                        <input type="text" v-model="loginItem" maxlength="12" size="18">
                                    </div>
                                    <select v-if="!addLogin" v-model="partner.logins_location">
                                        <option disabled value=0>Select or Add</option>
                                        <option v-for='loginLocation in loginLocations' :key="loginLocation.id" v-bind:value="loginLocation.id" >{{ loginLocation.location_item }}</option>
                                    </select>
                                </div>
                                <div class="flex" >
                                    <button v-if="!addLogin" title="Add" type="button" class="focus:outline-none" @click="addLogin=!addLogin">
                                        <svg class="w-4 h-4" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                     <button v-if="addLogin" title="Cancel" type="button" class="focus:outline-none" @click="addLogin=!addLogin">
                                        <svg class="w-4 h-4" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                    </button> 
                                </div>
                            </div>
                            <div class="flex">
                                <div class="pt-2 text-xs font-bold text-affypink-dark">PAYOUT METHOD</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttPayoutMethod">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                                <input type="text" v-model="partner.payout_method" maxlength="10" size="13">
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">PAYOUT RATE AMT</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttPayoutRateAmt">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                                <input v-model="partner.payout_rate_amount"  size="5">
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">COOKIE LIFE (days)</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttCookieLife">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                                <input  v-model="partner.cookie_life_days"  size="5">
                            </div>
                            <div class="flex">
                                <div class="text-xs font-bold text-affypink-dark">PAYOUT RATE %</div>
                                <div class="pl-2" >
                                    <button v-tooltip.top-center="ttPayoutRatePercent">
                                        <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            <div class="text-sm pb-2 text-gray-600">
                                <input v-model="partner.payout_rate_percent"  size="5">
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </form> 
    </div>
</template>

<script>    
import axios from 'axios'

export default {
    name: 'editpartner',
    data() {
        return {
            ttPartnerName: 'Add the affiliate Partner Name.<br> This must be unique.',
            ttMainSiteURL: 'The main site URL for<br>your affiliate partner <br>eg semrush.com',
            ttNetworkName: 'Add Affiliate partner network<br>name if the partner is in<br>a network eg Impact.',
            ttRegisteredEmail: 'The email you use to<br>register with the partner.',
            ttCategory: 'Create you own Affiliate<br>Partner categories<br>12 chars maximum', 
            ttStatus: 'Select the partner status',
            ttPartnerSiteDescription: 'Partner description',
            ttPartnerSiteNotes: 'Partner notes',
            ttAffiiliateACcode: 'You unique affiliate code<br>given to you by the partner', 
            ttAffiliateContactName: 'Contact name for your<br>affiliate partner manager',
            ttAffiliateContactEmail: 'Contact email your<br>affiliate partner manager',
            ttTrackingURL: 'Full tracking URL which<br>includes your affiliate code',
            ttTrackingShortURL: 'Short tracking URL<br>eg getus.at/semrush',
            ttPartnerAdminLogin: 'Admin login URL for<br>affiliate partner account',
            ttLogins: 'Where you have stored your logins<br>for your admin account eg Lastpass',
            ttPayoutMethod: 'How you are paid by your<br>affiliate partner eg CHEQUE',
            ttPayoutRateAmt: 'Payout rate amount<br>per sale',
            ttCookieLife: 'Number of days the<br>tracking cookie is active',
            ttPayoutRatePercent: 'Payout rate percentage',
            dataISvalid: false,
            token: localStorage.getItem('user-token') || null,
            userid: localStorage.getItem('user-id') || null,
            registered_email: "",
            user_category: null,
            site_name: "",
            affy_status: null,
            site_network_name: "",
            site_description: "",
            affiliate_account_code: "",
            logins_location: null,
            payout_rate_percent: "",
            cookie_life_days: "",
            payout_rate_amount: "",
            tracking_url: "",
            getusat_tracking_short_url: "",
            site_notes: "",
            payout_method: "",
            affiliate_contact_email: "",
            affiliate_manager_contact: "",
            mainsite_url: "",
            affiliate_login_url: "",
            saveOK: false,
            showeditpartner: false,
            statusOptions: [],
            categories: [],
            loginLocations: [],
            addLogin: false,
            addCat: false,
            errors: [],
            loginItem: '',
            errorsFound: false,     // general errors found
            found: false,           // for if partner already exist           
        }
    },
    props: {
        partner: {},
    },

    created() {

//  GET USER LOGIN LOCATIONS
    let axiosConfig = { headers: {'Authorization': 'Token ' + this.token } };
    axios.get("api/usrloginlocations/", axiosConfig)
    .then(resp => {
        this.loginLocations = resp.data;
    })  
    .catch(err => {
      console.log("err:"+err);
    })

//  GET USER CATEGORIES
    axios.get("api/usercategories/", axiosConfig)
    .then(resp => {
      this.categories = resp.data;
    })
    .catch(err => {
      console.log("err:"+err);
    })

//  GET GLOBAL STATUS RECORDS
    axios.get("api/globalstatus/", axiosConfig)
    .then(resp => {
      this.statusOptions = resp.data;
    })
    .catch(err => {
      console.log("err:"+err);
    })

    },

methods: {

//  VALIDATE THE PARTNER DATA BEFORE SAVING
    async checkForm() {
        this.errorsFound=false;     // hide error section on page
        this.errors = [];           // reset error message array

        // set nulls to zero
        if (this.partner.payout_rate_percent == "" || this.partner.payout_rate_percent === null) { this.partner.payout_rate_percent=0 }
        if (this.partner.cookie_life_days == "" || this.partner.cookie_life_days === null) { this.partner.cookie_life_days=0 }
        if (this.partner.payout_rate_amount == "" || this.partner.payout_rate_amount === null) {  this.partner.payout_rate_amount=0 }

        if(!this.partner.site_name) {
            this.errors.push('Partner Name - This is required') 
        }
            
        if ( isNaN( this.partner.payout_rate_percent )) {
            this.errors.push('Payout Rate % - This needs to be a number')
        }
        if ( isNaN( this.partner.cookie_life_days )) {
            this.errors.push('Cookie Life Days - This needs to be a number')
        }
        if ( isNaN( this.partner.payout_rate_amount ) ) {
            this.errors.push('Payout Rate Amt This needs to be a number')
        }
        
        if (this.errors.length > 0) { 
            this.errorsFound=true;
            const resp = false;
            return resp; } 
        else { 
            this.errorsFound=false;
            const resp = true;
            return resp; 
        }                                       //   e.preventDefault();
    },

    async checkIfExists() {
        
        let axiosConfig = {
            headers: {'Authorization': 'Token ' + this.token},
            params: {'search': this.site_name },
        };
        const resp = await axios.get('api/affypartnerlist/', axiosConfig );
        return resp;
    },

    async savePartner() {

    this.errorsFound=false;  //hide errors message
    this.errors = [];
    this.found = false;

    if(this.site_name) {
        await this.checkIfExists()
        .then(resp => {
            if (resp.data.length > 0) {
                this.found = true;
                this.errors.push("Partner Name already exists - it must be unique");
                this.errorsFound = true ;    // make visible of form
            } else { this.found = false; }
        })
        if (this.found) {       // bomb out right now - do rest of validation later
            return
        }
    }

    await this.checkForm() 
    .then(resp => {
        if (this.errors.length > 0) {
            this.errorsFound = true ;
        } else { this.errorsFound = false; }
    })

    if (this.errorsFound) {       // bomb out right now 
        return
    }

    this.addPartnerRecord()
 },

//  if data valid add new partner record
    async addPartnerRecord () {

    if (!this.errorsFound) {
        
        let axiosConfig = { headers: {'Authorization': 'Token ' + this.token } };
         
//      PROCESS ADDING NEW CATEGORY IF ONE MANUALLY ADDED
        if (this.catItem != '') {
            await this.saveCat()
        }

//      PROCESS ADDING NEW LOGINS IF ONE MANUALLY ADDED

        if (this.loginItem != '') { 
            await this.saveLogin()
        }
    
            var postData = {
                owneruserid: this.partner.owneruserid,
                user_category: this.partner.user_category,
                registered_email: this.partner.registered_email,
                site_name: this.partner.site_name,
                affy_status: this.partner.affy_status,
                site_network_name: this.partner.site_network_name,
                site_description: this.partner.site_description,
                affiliate_account_code: this.partner.affiliate_account_code,
                payout_method: this.payout_method,
                affiliate_contact_email: this.affiliate_contact_email,
                affiliate_manager_contact: this.affiliate_manager_contact,
                site_notes: this.site_notes,
                logins_location: this.partner.logins_location,
                payout_rate_percent: this.partner.payout_rate_percent,
                cookie_life_days: this.partner.cookie_life_days,
                payout_rate_amount: this.partner.payout_rate_amount,
                tracking_url: this.partner.tracking_url,
                getusat_tracking_short_url: this.partner.getusat_tracking_short_url,
                mainsite_url: this.partner.mainsite_url,
                affiliate_login_url: this.partner.affiliate_login_url,
            };
            
            axios.put(`api/affypartners/${this.partner.id}/`,postData, axiosConfig)
            .then(res => {
             //   console.log(res.data)
               
                this.saveOK = true;
                this.timer = setTimeout(() => { 
                    this.saveOK = !this.saveOK;         // hide after 2 seconds
                    }, 2000);
                    // fix refresh needed for data on main form             // refresh data on close
            })      
            .catch(err => {
                console.log("err:"+err);
            })

        }
    },

//   SAVE NEW LOGIN IF ONE ADDED MANUALLY  ON PARTER RECORD SAVE
    async saveLogin() {
        var postData = {
            owneruserid: this.userid,
            location_item: this.loginItem,
        }
        let axiosConfig = { headers: {'Authorization': 'Token ' + this.token } };
        await axios.post("api/usrloginlocations/", postData, axiosConfig)
        .then(resp => {
         //   console.log(resp.data);
            this.partner.logins_location = resp.data.id;
        })
        .catch(err => {
            console.log("err:"+err);
        })
    },

//  SAVE NEW CATEGORY IF ONE ADDED MANUALLY  ON PARTER RECORD SAVE
    async saveCat() {

        let axiosConfig = { headers: {'Authorization': 'Token ' + this.token } };
        var postData = {
            owneruserid: this.userid,
            user_category: this.catItem
        }
        await axios.post("api/usercategories/", postData, axiosConfig)
        .then(resp => {
         //   console.log(resp.data);
            this.partner.user_category = resp.data.id
        })
        .catch(err => {
            console.log("err:"+err);
        })
    },
    },
}
</script>

<style>

.tooltip {
  display: block !important;
  z-index: 10000;
  font-size: small;
}

.tooltip .tooltip-inner {
  background:#e75480;
  color: black;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: #e75480;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>