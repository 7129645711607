<template>
    <div >  
        <div class=" flex justify-between bg-white border-b shadow-sm">
            <div class="pl-2 pt-2 pb-2">
                <div class="flex">
                    <a href="https://www.cloudways.com/en/?id=426795" target="_top">
                        <img :src="require(`@/assets/images/banner-image.jpg`)" alt="Load WordPress Sites in as fast as 37ms!" title="Load WordPress Sites in as fast as 37ms!" width="728" height="90"/>
                    </a>
                </div>
            </div> 
            <div v-if="displayLogin===true" class="pl-2 pt-2 pb-2 pr-2  items-center flex">
                <div class="pr-2">
                    <TheAccount/>
                </div>
            </div> 
        </div> 
    </div>
                
</template>

<script>    
import TheAccount from '@/components/template-parts/TheAccount.vue'
import { TokenService } from '../storage/service'

export default {
  name: 'bannertop',
  components: {
    TheAccount,
  },

  data() {
        return {
          displayLogin: false,   
          token: '',       
        }
  },

    created() {
        // eslint-disable-next-line no-unused-vars
       // let token;
        this.token = TokenService.getToken();
        this.displayLogin=false;
        //  undefined comes in after verifiying email address on sign up
        if (this.token!==null && this.token!=='undefined' ) {
            this.displayLogin=true;
        }
        //console.log("token:"+this.token);
        //console.log("displayLogin:"+this.displayLogin);

    },
}
</script>