<template>
  <div class="" >
    <div class="min-h-screen shadow-lg "> 
      <div class="shadow-sm mr-2 min-h-screen"> 
        <div class="flex font-bold items-center text-xl ml-8 pt-6">
          Get to the top faster with Affyboy
          <svg class="w-12 h-12 pt-1" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg> 
        </div>
        <div class="p-4 ml-4 font-bold text-xl ">
          RELEASE NOTICES
        </div>
        <div class="text-xl font-bold ml-8 pt-6 text-gray-500">Latest Release</div>

        <div class="flex">
          <div class="text-xl font-bold ml-8 pt-6 text-gray-300">BETA</div>
          <div class="text-xs pt-8 pl-2 text-gray-300">v 1 . 0 . 24</div>
        </div>

        <div class="p-6 ml-8">
            <div class="pt-2 font-bold">
                v 1.0.24  - 24 Sept 2021
            </div>
            <div class="text-s pl-4 pt-2">
                <div>- Bug fixes with registration screen</div>
                <div>- Changed SMTP provider</div>
            </div>
        </div>

        <div class="p-6 ml-8">
            <div class="pt-2 font-bold">
                v 1.0.23  - 16 Sept 2021
            </div>
            <div class="text-s pl-4 pt-2">
                <div>- Fixed issues with login and password reset</div>
                <div>- Active Login link added to List Partner screen</div>
                <div>- Active #links added to List Partner screen</div>
                <div>- Links Edit screen full link and short link active link added</div>
                <div> - Link Partners screen Notes field added</div>
                <div> - Fixed existing tool tips and added new ones</div>
            </div>
        </div>
 
        <div class="pt-4 ml-4">Last Updated Date: 24 Sept 2021</div>

     </div>  
    </div>   
  </div>        
</template>

<script>


export default {
  name: 'releasenotices',
  
  data() {
        return {
         
        }
  },
  

  methods: {

  

  },
}
</script>