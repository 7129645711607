<template>
  <div class="" >
    <div class="min-h-screen shadow-lg "> 
      <div class="shadow-sm mr-2 min-h-screen"> 
        <div class="flex font-bold items-center text-xl ml-8 pt-6">
          Get to the top faster with Affyboy
          <svg class="w-12 h-12 pt-1" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg> 
        </div>
        <div class="p-4 ml-4 font-bold">
          PRIVACY STATEMENT
        </div>
        <div class="p-14">
            <div class="pt-4">
 Affyboy.com (“Affyboy”) has created this privacy statement (“Statement”) in order to describe how we collect and use the personal information you provide on our website Affyboy.com and all its sub-sites and related sites (together, the “Site”). Your privacy is of great importance to us. Please read the following to learn more about our Statement. By visiting or using the Website or Services (as defined below) in any manner, you acknowledge that you accept the practices and policies outlined in this Statement and you hereby consent that we will collect, use, and share your information in the following ways. Any capitalized terms used herein without definition shall have the meaning given to them in our Terms of Use.</div>
<div class="pt-4">Affyboy complies with the EU-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information from European Union member countries. </div>
<div class="pt-4">WHAT DOES THIS STATEMENT COVER?
<div class="pt-4">This Statement covers our treatment of personally identifiable information (“Personal Information”) that we gather when you are accessing or using our Website or Services. This policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage. Data stored in our Software as a Service solution by our customers is subject to the customers’ privacy policies, not ours.</div>

<div class="pt-4">We do not knowingly collect or solicit personal information from anyone under the age of 13 or knowingly allow such persons to register for the Services (as that term is defined below). No one under age 13 may provide any personal information to us or on the Services.</div>

<div class="pt-4">COLLECTED INFORMATION:</div>

<div class="pt-4">2.1 Information that you provide to us</div>

<div class="pt-4">We require customers who register to use the services offered on our Site (collectively, the “Service”) to give us contact information, such as their name, company name, address, phone number, and e-mail address, and financial qualification and billing information, such as billing name and address, credit card number, and the number of users within the organization that will be using the Service. At the time you express interest in attaining additional information, or when you register for the Service, we may also ask for additional personal information, such as title, department name, fax number, or additional company information, such as annual revenues, number of employees, or industry. Customers can opt out of providing this additional information by not entering it when asked. Customers can update or remove their personal information at any time by logging into the Website and editing their Personal Information within Setup. Customers can view their updated profile to confirm their edits have been made.
</div>
<div class="pt-4">2.2 Information that is collected automatically
</div>
<div class="pt-4">Affyboy may also collect certain information from visitors to and customers of the Site, such as Internet addresses. This information is logged to help diagnose technical problems, and to administer our Site in order to constantly improve the quality of the Service. We may also track and analyze non-identifying and aggregate usage and volume statistical information from our visitors and customers and provide such information to third parties.
</div>
If a user elects to use our referral service for informing a friend about our site, we ask them for the friend’s name and email address. Affyboy will automatically send the friend a one-time email inviting them to visit the site. Affyboy does not store this information.

Technologies such as cookies, beacons, scripts and tags are used by us and our third party partners. These technologies are used in analyzing trends, administering the website, tracking users’ movements around the site, and gathering demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual and aggregated basis. Various browsers may offer their own management tools for removing these types of tracking technologies.

We use Local Shared Objects, such as Flash cookies, and Local Storage, such as HTML5, to store content information and preferences. Various browsers may offer their own management tools for removing HTML5. Third parties with whom we partner to provide certain features on our website or to display advertising based upon your web browsing activity also use Flash cookies to collect and store information.

Our third party partners also employ clear gifs (a.k.a. Web Beacons/Web Bugs), images, and scripts that help them better manage content on our site. We do not tie the information gathered to our Customers’ or Users’ personal information.

Our website may include social media features, such as the Facebook Like button, and widgets, such as the Share this button or interactive mini-programs that run on our website. These features may collect your Internet protocol address, which page you are visiting on our website, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our website. Your interactions with these features are governed by the privacy statement of the company providing it.

<div class="pt-4">2.3 Cookies:</div>

When you interact with the Affyboy Website we strive to make that experience easy and meaningful. When you come to our Web site, our Web server sends a cookie to your computer. Cookies are files that Web browsers place on a computer’s hard drive and are used to tell us whether customers and visitors have visited the Site previously.  If you click on a link to a third party website, such third party may also transmit cookies to you.

Standing alone, cookies do not identify you personally. They merely recognize your browser. Unless you choose to identify yourself to Affyboy, either by responding to a promotional offer, opening an account, or registering for a 30-day Test Drive, you remain anonymous to Affyboy. Cookies come in two flavors: session and persistent-based. Session cookies exist only during an online session. They disappear from your computer when you close your browser software or turn off your computer. Persistent cookies remain on your computer after you’ve closed your browser or turned off your computer. They include such information as a unique identifier for your browser.

Affyboy uses session cookies containing encrypted information to allow the system to uniquely identify you while you are logged in. This information allows Affyboy to process your online transactions and requests. Session cookies help us make sure you are who you say you are after you’ve logged in and are required in order to use the Affyboy application. Affyboy uses persistent cookies, that only Affyboy can read and use, to identify the fact that you are a Affyboy customer or prior Affyboy Website visitor (whatever the case may be). We are especially careful about the security and confidentiality of the information stored in persistent cookies. For example, we do not store account numbers or passwords in persistent cookies. Users who disable their Web browsers’ ability to accept cookies will be able to browse our Website but will not be able to successfully use our Service.

<div class="pt-4">USE OF YOUR INFORMATION:</div>

Affyboy uses the information that we collect to set up the Service for individuals and their organizations. We may also use the information to contact customers to further discuss customer interest in our company, the Service that we provide, and to send information regarding our company or partners, such as promotions and events. Customers are invited to receive an email newsletter by providing an email address. Customer email addresses and any personal customer information will not be distributed or shared with any third parties.

Customers can opt out of being contacted by us, or receiving such information from us, at any time by sending an email to admin@Affyboy.com. 

Separately, customers are also asked to provide an email address when registering for the Service, in order to receive a username and password. We may also email information regarding updates to the Service or company, and will send a Customer Newsletter. Again, email will not be distributed or shared and customers can opt out of receiving any communication by emailing admin@Affyboy.com at the time it is distributed, or at the time any customer registers for the Service.

Except as we explicitly state at the time we request information, or as provided for below, in our terms of service or any other separate agreement which you may enter into with us, we do not disclose to any third-party the information provided. All financial and billing information that we collect through the Site is used solely to check the qualifications of prospective customers and to bill for the Service. This billing information is not used by Affyboy for marketing or promotional purposes. Affyboy uses a third-party intermediary to manage the credit card processing. This intermediary is solely a link in the distribution chain, and is not permitted to store, retain, or use the information provided, except for the sole purpose of credit card processing. Other third parties, such as content providers, may provide content on the web Site but they are not permitted to collect any information nor does Affyboy share any user information with these parties.

Customers of the Service will be using the Site to host data and information (“Data”). Affyboy will not review, share, distribute, print, or reference any such Data except as provided in the Affyboy terms of use or in any other separate agreement entered into with you, or as may be required by law. Individual records may at times be viewed or accessed only for the purpose of resolving a problem, support issue, or suspected violation of the Master Subscription Agreement, or as may be required by law. Of course, customers are responsible for maintaining the confidentiality and security of their user registration and password.

<div class="pt-4">THIRD-PARTY SITES:</div>

The Site contains links to other web sites. Affyboy is not responsible for the privacy practices or the content of these other web sites. Customers and visitors will need to check the policy statement of these others web sites to understand their policies. Customers and visitors who access a linked site may be disclosing their private information. It is the responsibility of the user to keep such information private and confidential.

<div class="pt-4">STORAGE LOCATION OF PERSONAL INFORMATION:</div>

If you are located outside of Singapore, the information that we collect from you may be transferred to, and stored and processed at, a destination in Singapore. By submitting information, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your information is treated securely and in accordance with this Statement.

<div class="pt-4">SECURITY:</div>

The security of your Personal Information is important to us.  We use commercially reasonable efforts to store and maintain your Personal Information in a secure environment.  We take technical, contractual, administrative, and physical security steps designed to protect Personal Information that you provide to us.  We have implemented procedures designed to limit the dissemination of your Personal Information to only such designated staff as are reasonably necessary to carry out the stated purposes we have communicated to you. You are also responsible for helping to protect the security of your Personal Information.  For instance, never give out your email account information for the Services to third parties password.

<div class="pt-4">SHARING OF PERSONAL INFORMATION WITH THIRD PARTIES:</div>

We neither rent nor sell your Personal Information or in personally identifiable form to anyone. We share such Personal Information in personally identifiable form with third parties as described below. In cases of onward transfer to third parties of data of EU individuals received pursuant to the EU-US Privacy Shield, Affyboy,   is potentially liable.

<div class="pt-4">7.1 Trusted Third Parties:</div>

We may from time to time employ other companies and people to perform tasks on our behalf and need to share your information with them to provide the Services to you. Unless we tell you differently, such third parties do not have any right to use the Personal Information we share with them beyond what is necessary to assist us. This includes third party companies and individuals employed by us to facilitate our Services, including the provision of maintenance services, database management, Web analytics and general improvement of the Services.

<div class="pt-4">7.2 Business Transfers:</div>

We may choose to buy or sell assets. In these types of transactions, customer information is typically one of the business assets that is transferred. Also, if we (or substantially all of our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, Personal Information would be one of the assets transferred to or acquired by a third party. You will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.

<div class="pt-4">7.3 Protection of Affyboy and Others:</div>

We reserve the right to access, read, preserve, and disclose any information that we reasonably believe is necessary to comply with law or a court order; enforce or apply our conditions of use and other agreements; or protect the rights, property, or safety of Affyboy, our employees, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction. We also may be required to disclose an individual’s Personal Information in response to a lawful request by public authorities, including to meet national security or law enforcement requirements.

<div class="pt-4">7.4 With Your Consent:</div>

Except as set forth above, you will be notified when your Personal Information may be shared with third parties, and will be able to prevent the sharing of this information.

<div class="pt-4">OPT-OUT POLICY:</div>

Affyboy offers its visitors and customers a means to choose how we may use information provided. If, at any time after registering for information or ordering the Service, you change your mind about receiving information from us or about sharing your information with third parties, send us a request specifying your new choice to admin@Affyboy.com. Customers can also opt out of being contacted by us, or receiving information from us, at any time by sending an email to admin@Affyboy.com.

<div class="pt-4">ACCESS – CORRECTING & UPDATING YOUR INFORMATION:</div>

If customers need to update or change registration information they may do so by editing the user or organization record. To update a User Profile, log on to Affyboy as the Account Admin and select Users to add or update information. To update Organization’s data, log on to Affyboy and select Account. To update billing information please email admin@Affyboy.com. To discontinue the Service, email admin@Affyboy.com. If you have any questions about viewing or updating information we have on file about you, please contact us at admin@Affyboy.com. We will respond to your request to access within 30 days. Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to ask us for a notice identifying the categories of Personal Information which we share with our affiliates and/or third parties for marketing purposes, and providing contact information for such affiliates and/or third parties. If you are a California resident and would like a copy of this notice, please submit a written request to: admin@Affyboy.com.

Additional Information for Certain Jurisdictions
We provide additional information about the privacy, collection, and use of personal information of prospective and current customers located in certain jurisdictions.

<div class="pt-4">10.1 California</div>

These additional disclosures are required by the California Consumer Privacy Act:

Your Rights. You may have the right under the California Consumer Privacy Act to request information about the collection of your personal information by us, or access to or deletion of your personal information. If you wish to do any of these things, please contact us (for AWS customers) or contact us at the address under Contacts, Notices, and Revisions above (for AWS customers and non-customers). Depending on your data choices, certain services may be limited or unavailable.

No sale of personal information. In the preceding twelve months, we have not sold any personal information of consumers, as those terms are defined under the California Consumer Privacy Act.

No Discrimination. We will not discriminate against any consumer for exercising their rights under the California Consumer Privacy Act.

<div class="pt-4">10.2 European Economic Area</div>

Processing. We process your personal information on one or more of the following legal bases:

As necessary to enter into a contract with you or a legal entity you represent, to perform our contractual obligations, to provide our Services to you, to respond to requests from you, or to provide customer support;
- where we have a legitimate interest, as described in this Privacy;
- as necessary to comply with relevant law and legal obligations, including to
- respond to lawful requests and orders; or
with your consent.
Your Rights. Subject to applicable law, you have the right to:
<div class="pt-4">
- ask whether we hold personal information about you and request copies of such personal information and information about how it is processed;
- request that inaccurate personal information is corrected;
- request deletion of personal information that is no longer necessary for the purposes underlying the processing, processed based on withdrawn consent, or processed in non-compliance with applicable legal requirements;
- request us to restrict the processing of personal information where the processing is inappropriate;
- object to the processing of personal data;
- request portability of personal information that you have provided to us (which does not include information derived from the collected information), where the processing of such personal information is based on consent or a contract with you and is carried out by automated means; and
- lodge a complaint with our principal supervisory authority.
</div>
<div class="pt-4">You can exercise your rights of access, rectification, erasure, restriction, objection, and data portability by contacting us. If you wish to do any of these things please use the “contact us”above.

When you consent to our processing your personal information for a specified purpose, you may withdraw your consent at any time, and we will stop any further processing of your data for that purpose.

Transfers outside of the EEA. When we transfer your personal information outside the EEA we do so in accordance with the terms of this Privacy Notice and applicable data protection law. This may include the transfer of data in accordance with the EU-US and Swiss-US Privacy Shield frameworks (for transfers to the US) or pursuant to data transfer agreements that incorporate the Standard Contractual Clauses approved by the EU Commission.
</div>
<div class="pt-4">CHANGES TO THIS STATEMENT:</div>

We may amend this Statement from time to time. Use of information we collect now is subject to the Statement in effect at the time such information is used. If we make material changes or changes in the way we use Personal Information, we will notify you by posting an announcement on our Website or sending you an email prior to the change becoming effective. You are bound by any changes to the Statement when you use the Website after such changes have been first posted.

<div class="pt-4">ADDITIONAL INFORMATION AND CONTACT DETAILS:</div>

Questions regarding this Statement or the practices of this Site should be directed to Affyboy’s Security Administrator by e-mailing such questions to admin@Affyboy.com 

<div class="pt-4">Effective Date:  Sept 2021</div>

</div>

        </div>
      </div>
    </div>   
  </div>        
</template>

<script>


export default {
  name: 'privacy',
  
  data() {
        return {
         
        }
  },
  

  methods: {

  

  },
}
</script>