<template>
    <div class="">  
        <div class="flex w-full justify-end pt-2 pr-4">
            <div class="flex content-right">
                <div v-if="!showeditlink" class="pl-2 bg-blue-500 px-2 text-white py-2 rounded flex h-8 items-center transition duration-300 ease-in-out hover:bg-blue-600">
                    <button type="button" v-on:click="deletevisible=!deletevisible, hideEdit=!hideEdit" class="focus:outline-none">
                        <div class="flex">
                            <div class="pr-1 pt-1 font-bold text-xs">DELETE</div>
                            <div>
                                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg> 
                        </div>
                        </div>
                    </button>
                </div>

                <div v-if="!showeditlink && !hideEdit" class="ml-2 bg-blue-500 px-2 text-white py-2 rounded flex h-8 items-center transition duration-300 ease-in-out hover:bg-blue-600">
                    <button type="button" v-on:click="editLink" class="focus:outline-none">
                        <div class="flex">
                            <div class="pr-1 pt-1 font-bold text-xs">EDIT</div>
                            <div>
                                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>

        <!-- DELETE LINK -->
        <div v-if="deletevisible" >
            <DeleteLink v-bind:link="link" @hidedeletesection="hideDeleteValue" @deletedok="deleteOK"/>
        </div>

        <!-- DELETE Ok Message -->
        <div v-if="deleteok" >
            <DeleteOk/>
        </div>

         <!-- EDIT LINK -->
        <div v-if="showeditlink" >       <!-- show edit link -->
            <EditLink v-bind:link="link" />
        </div>

        <!-- SAVED Ok Message -->
        <div v-if="savedok" >
            <SavedOk/>
        </div>

        <div v-if="!showeditlink">   <!-- show edit link screen -->
            <div class="relative p-2 bg-white w-full  m-auto flex-col flex">
                <div class="bg-white p-2 pb-2 mt-3 rounded-lg shadow">
                    <div class="grid grid-cols-2 gap-3 ">
                        <div class="bg-red-200 rounded p-2">
                            <div class="text-xs text-affypink-dark">
                                PARTNER SITE
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ formatField(link.site_name) }}
                            </div>
                            <div class="flex text-xs text-affypink-dark">
                                FULL LINK
                                <a :href="link.owner_link_long" target=_blank class="pl-2">
                                    <svg class="w-4 h-4" fill="none" stroke="#807e7e" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                                </a>
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ formatField(link.owner_link_long) }} 
                            </div>
                            <div class="flex text-xs text-affypink-dark">
                                SHORT LINK
                                <a :href="link.owner_link_short" target=_blank class="pl-2">
                                        <svg class="w-4 h-4" fill="none" stroke="#807e7e" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                                </a>
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ formatField(link.owner_link_short) }} 
                            </div>
                            <div class="text-xs text-affypink-dark">
                                LINK TYPE
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ formatField(link.linked_from_typedesc) }}  
                            </div>
                        </div>
                        <div class="bg-red-200 rounded p-2">
                            <div class="text-xs text-affypink-dark">
                                LINK DESCRIPTION
                            </div>
                            <div class="text-sm pb-2 text-white">
                                {{ formatField(link.owner_link_desc) }}
                            </div>
                            <div class="text-xs text-affypink-dark ">
                                LINK NOTE
                            </div>
                            <div class="text-sm pb-2  text-white">
                                {{ formatField(link.owner_link_note) }}
                            </div>
                        </div>
                    </div>
                    <div class="flex text-xs pb-2 text-gray-400">
                        Date Added {{ link.date_added }} Last Edited {{ link.last_edited }} 
                    </div>
                </div>  
            </div>
        </div>  <!-- end of display information for link -->
    </div>
</template>

<script>    
import EditLink from '@/components/page-parts/links/EditLink.vue'
import DeleteLink from '@/components/page-parts/links/DeleteLink.vue'
import DeleteOk from '@/components/page-parts/links/DeleteOk.vue'

export default {
  name: 'linkfull',
  components: {
  EditLink,
  DeleteLink,
  DeleteOk,
  },

data() {
    return { 
        visible: false,
        hidevisible: false,
        hidedeletesection: false,
        deletevisible: false,  
        deleteok: false, 
        showeditlink: false,   
        savedok: false, 
        hideEdit: false,
    }
},

props: {
    link: {},
    },

beforeCreate: function(){
    if (localStorage.getItem('user-token') === null) {
      window.location.href = '/';
        }
    },

created() {
    // eslint-disable-next-line no-unused-labels
    deleteok: false;
    // eslint-disable-next-line no-unused-labels
    showeditlink: false;
    },

methods: {

    formatField (fld) {
        if (fld === '' | fld === null) {
            return "NONE"
        } else return fld
    },

    toggleVisible() {
      this.visible = !this.visible;
    },

    hideDeleteValue: function(params) {
        this.deletevisible = params;
        this.hideEdit = params;
    },

    editLink() {
        this.showeditlink = true;
    },

    deleteOK: function(params) {
      this.deleteok = params;
      if( this.deleteok )
        {
        this.deletevisible=false;
        this.timer = setTimeout(() => { 
            this.deleteok = false;          // hide after 2 seconds
            this.$emit("autoclose", true);
            }, 2000);
        }
    },
    
  }
}

</script>