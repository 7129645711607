<template>
  <div class="">
    <div class="pl-2">
      <table class="table-auto w-full">
        <thead>
          <tr>
            <th class="text-left">
              <div class="flex">
                <div class="text-affypink">PARTNER SITE</div>
                <div>
                  <button @click="sortdown=!sortdown" class="focus:outline-none">
                    <svg v-if="sortdown" class="w-6 h-6 pl-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                    <svg v-else class="w-6 h-6 pl-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                  </button>
                </div>
              </div>
            </th>
            <th>
              <div class="text-xs text-left pl-1 text-affypink">CATEGORY</div>
              <div class="text-left">
                <select v-model="categoryItem" name="categoryItem" class="text-xs text-affypink-dark focus:outline-none">
                  <option value=0>SHOW ALL</option>
                  <option v-for="category in categories" :value=category.id :key="category.id">{{category.user_category}}</option>
                </select>
              </div>
            </th>
            <th>
              <div class="text-xs text-left pl-1 text-affypink">STATUS</div>
              <div class="text-left" >
                <select name="statusItem" v-model="statusItem" class="text-xs text-left text-affypink-dark focus:outline-none">
                  <option value=0>SHOW ALL</option>
                  <option v-for="statusOption in statusOptions" :value=statusOption.id :key="statusOption.id">{{statusOption.status_desc}}</option>
                </select>
              </div>
            </th>
            <th class="text-left text-affypink">AFFY CODE</th>
            <th class="text-left text-affypink">#LINKS</th>
            <th class="text-affypink">LOGIN</th>
          </tr>
        </thead>
        <tbody>
              <tr v-for="partner in sortedPartners" v-bind:key="partner.id">
                <td>
                    <button @click="curPartner(partner)">
                      <div class="flex items-center text-affygrey-dark">
                        <svg class="w-6 h-4 pr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                        <div v-if="partner.site_name && partner.site_name.length>20" :title="partner.site_name">{{ partner.site_name|truncate(20) }}</div>
                        <div v-if="partner.site_name && partner.site_name.length<21" >{{ partner.site_name }}</div>
                      </div>
                    </button>
                </td>
                <td class="pl-1 text-affygrey-dark">{{partner.user_category_desc}}</td>
                <td class="pl-1 text-affygrey-dark">{{partner.user_status_desc}}</td>
                <td class="pl-1 text-affygrey-dark">{{partner.affiliate_account_code}}</td>
                <td class="pl-1 text-affygrey-dark ">
                  <div class="flex">
                    <button @click="showLinked(partner.site_name)" class="hover:underline  focus:outline-none">
                      {{partner.num_affy_links}}
                    </button>
                    <div class="pt-1 pl-2">
                      <router-link :to="{ name: 'addlinkwithid', params: {partnerid:partner.id } }">
                        <button title="Add Link" type="button" class="focus:outline-none">
                          <svg class="w-4 h-4" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        </button>
                      </router-link>
                    </div>
                  </div>
                </td>
                <td v-if="partner.affiliate_login_url===null"></td>
                <td v-if="partner.affiliate_login_url!=null" class="pl-12">
                  <a :href="partner.affiliate_login_url" target=_blank>
                    <svg class="w-5 h-5" fill="none" stroke="#807e7e" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                  </a> 
                </td>
            </tr> 
        </tbody>
      </table> 
    </div>

    <div v-if="visible" >
      <!-- Dialog (full screen) to cover top layer - so on click off white closes -->
      <div class="fixed top-0 left-0 flex justify-end w-full h-full" @click.self="toggleVisible" style="background-color: rgba(0,0,0,.5);" >
        <div class="flex h-full p-2 text-left rounded-bl-lg bg-white shadow-xl md:max-w-xl md:p-3 lg:p-3 md:mx-0">
          <div class="text-center sm:mt-0 sm:ml-2 sm:text-left"  title="remove search">
            <span @click="toggleVisible" class="focus:outline-none">
              <svg class="w-6 h-6" fill="none" stroke="grey" viewBox="0 0 20 20" role="button" xmlns="http://www.w3.org/2000/svg">
              <title>Close</title>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M6 18L18 6M6 6l12 12"></path></svg>
            </span>
          </div>
          <div>
              <PartnerListFullDetails v-bind:partner="partner" @autoclose="autoCloseAfterDelete"/> 
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>  
import { bus } from '../../../main';
import PartnerListFullDetails from '@/components/page-parts/partner/PartnerListFullDetails.vue';
import axios from 'axios';

export default {
  name: 'partnerlist',
  components: {
    PartnerListFullDetails,
  },

data() {
        return {
          searchValue: '',
          token: localStorage.getItem('user-token') || null,
          tempPartner: [],
          categories: [],
          statusOptions: [],
          sortdown: true,
          visible: "",
          partners : [],
          partner: Object,
          autoclose: "",
          catSelected: 0,
          statusSelected: 0,
          categoryItem: 0,
          statusItem: 0,
 
        }
    },

filters: {
  
  truncate: function (value, size) {

    if (!value) return 'err';
      value = value.toString();

    if (value.length <= size) {
      return value;
    }
    return value.substr(0, size) + '...';
  }
},


computed: {
    
	sortedPartners() {

    bus.$on('searchChanged', (data) => {
      this.searchValue = data;
    })

    let tempPartner = this.partners;

//  SORT		
		tempPartner = tempPartner.sort((a,b) => {
			let fa = a.site_name.toLowerCase(), fb = b.site_name.toLowerCase();
			if (fa < fb) {
				return -1
			}
			if (fa > fb) {
				return 1
			}
			return 0
    })

//  SORT REVERSE    
    // Show sorted array in descending or ascending order
    if (!this.sortdown) {
      tempPartner.reverse()
    }

//  FILTER on SEARCH TERM
    if (this.searchValue != '' && this.searchValue) {
        tempPartner = tempPartner.filter((item) => {
          return item.site_name
            .includes(this.searchValue.toUpperCase())
        })
    } else {
          tempPartner = this.partners;
    }
//  FILTER ON CATEGORY & STATUS
      if (this.categoryItem > 0 || this.statusItem > 0 ) {
      //  console.log ("IN")
        tempPartner = tempPartner.filter((item) => {
          if (this.categoryItem > 0 && this.statusItem > 0 ) {
            return (item.user_category == this.categoryItem && item.affy_status == this.statusItem);
          } else if (this.categoryItem > 0) {
            return (item.user_category == this.categoryItem);
          } else if (this.statusItem > 0) {
            return (item.affy_status == this.statusItem);
          } 
        })
      }

    return tempPartner
	},
},

beforeCreate() {
    if (localStorage.getItem('user-token') === null) {
      window.location.href = '/';
    }
},

created() {

//  GET PARTNER RECORDS
    this.getPartners();

    let axiosConfig = {
      headers: {
        'Authorization': 'Token ' + this.token
      }
    };
//  GET USER CATEGORIES
    axios.get("api/usercategories/", axiosConfig)
    .then(resp => {
      this.categories = resp.data;
    })
    .catch(err => {
      console.log("err:"+err);
    })
//  GET GLOBAL STATUS RECORDS
    axios.get("api/globalstatus/", axiosConfig)
    .then(resp => {
      this.statusOptions = resp.data;
    })
    .catch(err => {
      console.log("err:"+err);
    })
    // eslint-disable-next-line no-unused-labels
    visible:false;
  },

methods: {

  showLinked: function(param) {
    bus.$emit("searchChanged", param);
   // console.log("param:"+param)
    this.$router.push('/links')
  },

  toggleVisible() {
      this.visible = !this.visible;
  },

  autoCloseAfterDelete: function(params) {
      this.visible = !params;     
      let axiosConfig = {
        headers: {
          'Authorization': 'Token ' + this.token
        }
      };
      axios.get("api/affypartners/", axiosConfig) //refresh the data 
      .then(res => (this.partners = res.data))
      .catch(err => console.log(err));       
    },

  getPartners() {
      
      let axiosConfig = {
        headers: {
          'Authorization': 'Token ' + this.token
        }
      };
      axios.get("api/affypartners/", axiosConfig)
      .then(resp => {
        this.partners = resp.data;
      })
      .catch(err => {
        console.log("err:"+err);
      })
   },

curPartner(partner) {
    // When a model is changed, the view will be automatically updated.
    this.partner = partner;
    this.toggleVisible()
   // console.log("partner:"+this.partner)
      }
  }, 
}

</script>