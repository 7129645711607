<template>  
    <div v-if="token!==null" class="border-blue-600 mt-6 bg-affyblue-light shadow-none border pb-4">      
        <router-link to="/list"> 
            <div v-on:click="SwitchMenu('listpartner')" class="flex mt-6">
                <div v-if="listpartnermenu" class="border-l-2 border-yellow-200 pr-1"></div>
                <div class="flex flex-row pl-2  pb-2 text-opacity-75 text-yellow-200 hover:text-white focus:text-yellow-400">
                    <svg class="w-10 h-10 pr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16"></path></svg>
                    <span class="pt-2">List Partners</span>
                </div>
            </div>
        </router-link>
        <router-link to="/add">
            <div v-on:click="SwitchMenu('addpartner')" class="flex">
                <div v-if="addpartnermenu" class="border-l-2 border-yellow-200 pr-1"></div>
                <div class="flex flex-row pl-2 pb-2 text-opacity-75 text-white hover:text-yellow-200">
                    <svg class="w-10 h-10 pr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <span class="pt-2">Add Partner</span>
                </div>
            </div>
        </router-link>
        <router-link to="/links"> 
            <div v-on:click="SwitchMenu('listlink')" class="flex">
                <div v-if="listlinkmenu" class="border-l-2 border-yellow-200 pr-1"></div>
                <div class="flex flex-row  pl-2 pb-2 text-opacity-75 text-white hover:text-yellow-200 focus:text-yellow-300">
                    <svg class="w-10 h-10 pr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                    <span class="pt-2">Link Locations</span>
                </div>
            </div>
        </router-link>
        <router-link to="/addlink"> 
            <div v-on:click="SwitchMenu('addlink')" class="flex">
                <div v-if="addlinkmenu" class="border-l-2 border-yellow-200 pr-1"></div>
                <div class="flex flex-row  pl-2 pb-2 text-opacity-75 text-white hover:text-yellow-200 focus:text-yellow-300">
                    <svg class="w-10 h-10 pr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <span class="pt-2">Add Link</span>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>    

export default {
    name: 'leftmenu',
    data() {
        return {
            token: localStorage.getItem('user-token') || null,
            listpartnermenu: false,
            addpartnermenu: false,
            listlinkmenu: false,
            addlinkmenu: false,
        }
    },
    methods: {
        SwitchMenu: function(currentpage) {
            this.listpartnermenu = false;
            this.addpartnermenu = false;
            this.listlinkmenu= false;
            this.addlinkmenu = false;
            if (currentpage=="listpartner") {
                this.listpartnermenu = true;
            }
            if (currentpage=="addpartner") {
                this.addpartnermenu = true;
            }
            if (currentpage=="listlink") {
                this.listlinkmenu = true;
            }
            if (currentpage=="addlink") {
                this.addlinkmenu = true;
            }
        }
    },
}

</script>