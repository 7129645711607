<template>
  <div  v-if="token==null" class="w-full p-2 pt-4 rounded-xl shadow-xl bg-affylogin-light min-h-screen">      
    <div class=" p-8 pb-3 bg-white w-full rounded-md shadow-sm focus-within:border-blue-500">
      <div class=" mx-auto">        
        <h1 class="text-2xl font-bold text-affypink text-center">Get Started</h1>
      </div>
      <div class="bg-white mx-auto pl-12 pr-12 pt-4 pb-4  my-6 rounded-lg shadow-2xl">
        <div>    
          <h3 class="font-bold text-xl pl-12 pr-12">Welcome to Affyboy</h3>
        </div>
        <div>    
          <h4 class="font-bold  text-l pt-6 pl-12 pr-12">RESET PASSWORD</h4>
          <p class="text-gray-600 pl-12 pr-12 pt-1">Add your email address below.</p>
        </div>
        <div class="mt-6">
          <form @submit.prevent="ResetPassword" @change="validateEmail" @blur="validateEmail" class="flex flex-col" method="POST" action="#">
            <div class="mb-2 pt-2 rounded bg-gray-200">
                <label class="block text-gray-700 text-xs font-bold mb-1 ml-3" for="email">Registered Email</label>
                <input type="email" v-model="currentemail" class="bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 focus:border-purple-600 transition duration-500 px-3 pb-1">
            </div>
            <button :disabled='isDisabled' type="button" @click="ResetPassword" class="focus:outline-none">
              <div class="mb-4 bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 rounded shadow-lg hover:shadow-xl transition duration-200" type="submit">
                Send Reset Email Now
              </div>
            </button>
          </form>
          </div>
          <div v-if="!usermessage" class="place-items-end flex">
            <button type="button" @click="Cancel" class="focus:outline-none">
              <div class="bg-red-400 hover:bg-purple-700 text-white font-bold px-3 rounded shadow-lg hover:shadow-xl transition duration-200">Cancel</div>
            </button> 
          </div>
          <div class="pt-2 text-l text-red-300 font-semibold" v-if="usermessage">  <!-- message -->
              <span v-html="displaymessage"></span>
          </div>
          <div v-if="usermessage" class="place-items-end flex">
            <button type="button" @click="Cancel" class="focus:outline-none">
              <div class="bg-red-400 hover:bg-purple-700 text-white font-bold my-4 px-3 rounded shadow-lg hover:shadow-xl transition duration-200">Close</div>
            </button> 
          </div>
          
          <div class="pb-3 pt-4 text-sm text-center">
                 <span v-html="message1"></span>
          </div>
        </div>
      </div>
    </div>
</template>

<script>  
import axios from 'axios'

export default {
  name: 'passwordreset',
  data() {
        return {
          currentemail: '',
          displaymessage: '',
          disableButton: false,
          usermessage: false,
          message1: 'If the confirmation email ends up in your SPAM box<br /><strong>please WHITELIST</strong>our domain email address.',
          token: localStorage.getItem('user-token') || null,
          }
      },

  computed: {
    isDisabled: function() {
      if (this.currentemail==='' || this.disableButton || this.disableButton ) {
        return true;
        } else {
          return false;
        }
    }
  },
 
  methods: {

    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    validateEmail() {
      this.displaymessage = '';
      this.disableButton = false;
      this.usermessage = false;

      if (!this.currentemail) {
        this.displaymessage = 'Please enter an email address';
        this.disableButton = true;
        this.usermessage = true;
      } else if (!this.validEmail(this.currentemail)) {
        this.displaymessage = 'Please enter a VALID email address';
        this.disableButton = true;
        this.usermessage = true;
      }
      },

    Cancel() {
    //  this.$emit("showresetpassword", false);  //show login instead
      this.$emit('selectedOptions', {
        showresetpassword: false,
        showregister: false,
        showlogin: true,
        showresetpasswordvalues: false,
      });

      console.log('cancelled');
    },

    async ResetPassword() {
      this.disableButton = true;
      const resp = await axios.post('api/password-reset/', {
    //  const resp = await axios.post('password-reset/', {
          email: this.currentemail,
      })
      .then(resp => {
        this.displaymessage = 'Password reset email address has been sent<br /><strong>Please check your email</strong>';
        this.disableButton = true;
        console.log ('Password reset email address has been sent')
      })
      .catch(err => {
        console.log("email Error:"+err);
        this.displaymessage = '<strong>Password reset error please try again.</strong><br />Are you sure you entered the<br />correct email address?';
        this.disableButton = false;
      })
      this.usermessage = true;
    },

  }
}
</script>