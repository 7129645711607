<template>
  <div class="items-center">
    <div class="flex">
      <div class="flex items-center border-l-4 pl-4 border-gray-100 h-16">
          <div>
            <svg class="w-9 h-9 bg-gray-300 rounded-full p-2 mr-2" fill="none" stroke="grey" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
          </div>
          <div>
              <div v-if="!showadminmenu" class="flex">
                <div class="p-3">{{ email }} </div>
                <button @click="showadminmenu=!showadminmenu" class="focus:outline-none"> 
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                </button>
              </div>
              <div v-if="showadminmenu" class="flex">
                <div class="p-3"> {{ email }}</div>
                <button @click="showadminmenu=!showadminmenu" class="focus:outline-none "> 
                 <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                </button>
              </div>
          </div>
          <!-- display drop down menu on click of menu drop down -->
          <div class="relative">
            <div v-if="showadminmenu" class="absolute right-2 mt-8 w-32 shadow-xl rounded-md bg-affypink text-sm p-4 ">
                <div>
                  <button @click="logout" class="m-1 pb-2 focus:outline-none font-semibold text-white">
                    Logout
                  </button>
                </div>
                <router-link to="/help">
                  <div v-on:click="showadminmenu=!showadminmenu" class="m-1 focus:outline-none font-semibold text-white">
                      Help
                  </div>
                </router-link>
            </div>
          </div>
          </div>
    </div>
  </div>
</template>

<script>    
import axios from 'axios'

export default {
  name: 'accounttop',
  data() {
        return {
          showadminmenu: false,
          email: localStorage.getItem('user-email') || null,
          token: localStorage.getItem('user-token') || null,
          userid: localStorage.getItem('user-id') || null,
          
        }
  },
  methods: {
    /* eslint-disable */
    logout() {
      this.loginerror = false;
      this.errormessage = '';
      let axiosConfig = {
        headers: {
          'Authorization': 'Token ' + this.token
        }
      };
    //  axios.post('dj-rest-auth/logout/', axiosConfig)
      axios.post('api/logout/', axiosConfig)
      .then(resp => {
      localStorage.removeItem('user-token')
      localStorage.removeItem('user-email')
      localStorage.removeItem('user-id')
      this.token = null;
      this.userid = null;
      this.email = null;
      window.location.href = '/'   //refresh so login screen show again
      })
      .catch((error) => {
          console.log("errcode:"+error.response.status)
      })
    },
    /* eslint-enable */
   }
}
</script>