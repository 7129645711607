<template>
    <div class="">
        
        <!-- save button for Edit -->
        <div class="flex justify-end">
            <div v-if="saveOK">
                <div class="bg-blue-500 rounded-tl-lg rounded-bl-lg text-xs text-affypink py-2 px-2">LINK UPDATED</div>
            </div>
            <div  class="bg-blue-500 px-2 text-white py-2 rounded flex h-8 items-center transition duration-300 ease-in-out hover:bg-blue-600">
                <button type="button" v-on:click="saveLink" class="focus:outline-none">
                    <div class="flex">
                        <div class="pr-1 pt-1 font-bold text-xs">SAVE</div>
                        <div>
                            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
                        </div>
                    </div>
                </button>
            </div>
        </div>


        <div class="relative p-2 bg-white w-full  m-auto flex-col flex">
            <div class="bg-white p-2 pb-2 mt-3 rounded-lg shadow">
                <div class="grid grid-cols-2 gap-3 ">
                    <div class="bg-red-200 rounded p-2">                  
                        <div class="flex">
                            <div class="text-xs font-bold text-affypink-dark ">PARTNER SITE</div>
                            <div class="pl-2" >
                                <button v-tooltip.top-center="ttPartnerSite">
                                    <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                </button>
                            </div>
                        </div>
                        <div class="flex text-sm pb-2">
                            <div class="pr-2 text-white font-bold">
                                {{ link.site_name }} 
                            </div>
                        </div>
                        <div class="flex">
                            <div class="text-xs font-bold text-affypink-dark">FULL LINK</div>
                            <div class="pl-2" >
                                <button v-tooltip.top-center="ttFullLink">
                                    <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                </button>
                            </div>
                        </div>
                        <div class="text-sm pb-2 text-gray-600">
                            <textarea placeholder="eg https://example.com" v-model="link.owner_link_long" rows="2" cols="28" maxlength="100">
                                </textarea>
                        </div>
                        <div class="flex">
                            <div class="text-xs font-bold text-affypink-dark">SHORT LINK</div>
                            <div class="pl-2" >
                                <button v-tooltip.top-center="ttShortLink">
                                    <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                </button>
                            </div>
                        </div>
                        <div class="text-sm pb-2 text-gray-600">
                            <input type="url" placeholder="eg https://getus.at/link" v-model="link.owner_link_short" maxlength="27" size="30">
                        </div>
                        <div class="flex">
                            <div class="text-xs font-bold text-affypink-dark">LINKED FROM TYPE</div>
                            <div class="pl-2" >
                                <button v-tooltip.top-center="ttLinkedFromType">
                                    <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                </button>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="pr-2 text-sm" >
                                <div class="pr-2 text-gray-600" v-if="addtype"> 
                                    <input type="text" v-model="linkitem" maxlength="12" size="18">
                                </div>
                                <div v-if="!addtype">
                                    <select id="linktypelist" v-model="link.linked_from_typedesc">
                                        <option disabled value="0">Select or Add</option>
                                        <option v-for='link in links' v-bind:value="link.linked_from_typedesc" :key="link.id" >{{ link.linked_from_typedesc }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex">
                                <button v-if="!addtype"  type="button" title="Add" @click="addtype=!addtype" class="focus:outline-none">
                                    <svg class="w-4 h-4" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                </button>
                                <button v-if="addtype" title="Cancel" type="button" class="focus:outline-none" @click="addtype=!addtype">
                                    <svg class="w-4 h-4" fill="none" stroke="blue" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                </button>
                            </div>
                        </div>
                        
                    </div>
                    <div class="bg-red-200 rounded p-2">
                        <div class="flex">
                            <div class="pr-2 text-xs font-bold text-affypink-dark">LINK DESCRIPTION</div>
                            <div class="pl-2" >
                                <button v-tooltip.top-center="ttLinkDescription">
                                    <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                </button>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="text-sm pb-2 text-gray-600">
                                <textarea v-model="link.owner_link_desc" rows="3" cols="28" maxlength="40">
                                </textarea>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="text-xs font-bold text-affypink-dark">LINK NOTE</div>
                            <div class="pl-2" >
                                <button v-tooltip.top-center="ttLinkNote">
                                    <svg class="w-4 h-4" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                </button>
                            </div>
                        </div>
                        <div class="text-sm pb-2 text-gray-600">
                            <textarea v-model="link.owner_link_note" rows="3" cols="28" maxlength="50">
                                </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
    </div>
</template>

<script>    
import axios from 'axios'
//import Vue from 'vue'
//import VTooltip from 'v-tooltip'
// Vue.use(VTooltip)

export default {
    name: 'editlink',
    data() {
        return {
            addtype: false,
            token: localStorage.getItem('user-token') || null,
            userid: localStorage.getItem('user-id') || null,
            site_name: "",
            owner_link_useraffyid: "",
            owner_link_long: "", 
            owner_link_short: "",
            linked_from_typedesc: "",
            owner_link_desc: "",
            owner_link_note: "",
            saveOK: false,  
            links: [],  
            linkitem: '',  
            errorsFound: false,
            errors: [],   
            ttPartnerSite: 'Partner site this link is connected to.',
            ttFullLink: 'Full URL for where link is located<br>must use http/https',
            ttShortLink: 'Short link to partner site this link is connected to. <br>Must use http/https eg https://getus.at/semrush',
            ttFullLinkSite: 'Partner site this link is connected to.',
            ttLinkedFromType: 'Add your own Link from type eg YouTube',
            ttLinkDescription: 'Optional<br />description about the link.',
            ttLinkNote: 'Optional<br />note about the link.',
        }
    },
    props: {
        link: {},
    },

    beforeCreate: function(){
    if (localStorage.getItem('user-token') === null) {
      window.location.href = '/';
        }
    },

    created: function(){
        this.getLinkTypes()     //gets partners
    },


    methods: {

        getLinkTypes() {
            // make sure users are logged in
            if (this.token === null) {
                window.location.href = '/';
            }
            let axiosConfig = {headers: {'Authorization': 'Token ' + this.token } };
            axios.get("api/linktypes/", axiosConfig)   
                 .then(function (response) {
                this.links = response.data;
                }.bind(this));
          },

//  VALIDATE THE LINKS DATA BEFORE SAVING
        checkForm() {
            this.errorsFound=false;     // hide error section on page
            this.errors = [];           // reset error message array

            // if (this.partneritem  === null | this.partneritem === 0 ) {
            //     this.errorsFound = true;
            //     this.errors.push('Partner Site Name must be selected') 
            // }
            if (this.errors.length) {
                this.errorsFound=true
            }
        },

        saveLink() {

            this.checkForm()
            if (this.errorsFound) {
                return
            }

            this.saveOK = false;
        
            let axiosConfig = {headers: {'Authorization': 'Token ' + this.token } };
            var postData = []

            if (this.addtype) {     // uses the text box
                postData = {
                id: this.link.id,
                owner_link_useraffyid: this.link.owner_link_useraffyid,
                owneruserid: this.userid,
                owner_link_short: this.link.owner_link_short,
                owner_link_long: this.link.owner_link_long,
                owner_link_desc: this.link.owner_link_desc,
                owner_link_note: this.link.owner_link_note,  
                linked_from_typedesc: this.linkitem.toUpperCase()
                }
            } else {            // uses the drop down 
                postData = {
                id: this.link.id,
                owner_link_useraffyid: this.link.owner_link_useraffyid,
                owneruserid: this.userid,
                owner_link_short: this.link.owner_link_short,
                owner_link_long: this.link.owner_link_long,
                owner_link_desc: this.link.owner_link_desc,
                owner_link_note: this.link.owner_link_note,  
                linked_from_typedesc: this.link.linked_from_typedesc,
                } 
            }

            axios.put(`api/affypartnerlinks/${this.link.id}/`,postData, axiosConfig)
            .then(res => {
               // console.log(res.data)
                this.saveOK = true;
                this.timer = setTimeout(() => { 
                    this.saveOK = !this.saveOK;         // hide after 2 seconds
                    }, 2000);
                // window.location.reload();
                // location.reload();
            })
            .catch(err => {
                console.log("UPDATING PARTNER:"+err)
            })

        }
    }
}
</script>

<style>

.tooltip {
  display: block !important;
  z-index: 10000;
  font-size: small;
}

.tooltip .tooltip-inner {
  background:#e75480;
  color: black;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: #e75480;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>